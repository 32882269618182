import * as yup from 'yup';

import {
    Alert,
    Badge,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import { AmsPermissionClaimType, authService } from '../../services/Auth';
import {
    AuspiceDto,
    CitationDto,
    Institution,
    InstitutionTypeDto,
    InstitutionalAccreditor,
    MeetingDateDto,
    ProfessionStandardsDto,
    ProgramStatusHistory,
    RecommendationDto,
    RecommendationSatellite,
    UserInfo,
    ProfessionDetail,
} from '../../common/Types';
import { Button, Input, PageTitle, Ribbon } from '../../components';
import { Controller, useForm } from 'react-hook-form';
import { Countries, ProgramStatus, States } from '../../common/Data';
import React, { useEffect, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';

import CitationForm from '../../components/CitationForm';
import { RecommendationSatellite as RecommendationSatelliteForm } from '../../components';
import Skeleton from 'react-loading-skeleton';
import { institutionService } from '../../services/InstitutionService';
import moment from 'moment';
import { professionService } from '../../services/ProfessionAdministration';
import { programService } from '../../services/ProgramService';
import { recommendationService } from '../../services/RecommendationService';
import { systemAdministration } from '../../services/SystemAdministration';
import { toast } from 'react-toastify';
import isDetached from 'react-hook-form/dist/utils/isDetached';

interface RecommendationParams {
    recommendationId?: string;
}

const Recommendation = () => {
    const [citations, setCitations] = useState<CitationDto[]>();

    const recommendationSchema: yup.ObjectSchema<RecommendationDto> = yup
        .object({
            recommendationId: yup.number().required(),
            caahepinstitutionId: yup
                .number()
                .notRequired()
                .transform((cv, ov) => {
                    return ov === '' || isNaN(ov) || !ov ? undefined : +cv;
                }),
            caahepprogramId: yup
                .number()
                .notRequired()
                .transform((cv, ov) => {
                    return ov === '' || isNaN(ov) || !ov ? undefined : +cv;
                }),
            institutionalAccreditorId: yup
                .number()
                .required('Institutional Accreditor is required')
                .transform((cv, ov) => {
                    return (ov || -1) === -1 ? undefined : +cv;
                }),
            professionStandardId: yup.number().required(),
            citations: yup.number().required().default(0),
            statusId: yup.number().required(),
            effectiveDate: yup.date().notRequired(),
            expirationDate: yup.date().notRequired(),
            prdue: yup.date().when('citations', {
                is: () => (citations?.length || 0) > 0,
                then: yup.date().required('PR Due is required').typeError('PR Due is required'),
                otherwise: yup
                    .date()
                    .notRequired()
                    .transform((cv, ov) => {
                        return !ov ? undefined : cv;
                    }),
            }),
            nextFullEval: yup
                .date()
                .required('Next Full Evaluation is required')
                .typeError('Next Full Evaluation is required'),
            meetingDateId: yup.number().required(),
            meetingDate: yup.date().required(),
            institutionTitle: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            institutionTypeId: yup
                .number()
                .required()
                .transform((cv, ov) => {
                    return ov === '' || isNaN(ov) || !ov ? undefined : cv;
                }),
            institutionAuspiceId: yup
                .number()
                .required()
                .transform((cv, ov) => {
                    return ov === '' || isNaN(ov) || !ov ? undefined : cv;
                }),
            institutionAddress: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            institutionAddress2: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            institutionAddress3: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            institutionAddress4: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            institutionCity: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            institutionState: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            institutionZip: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            institutionCountry: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            institutionPhone: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            institutionExtension: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            institutionWebSite: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            programProfessionId: yup.number().required(),
            programConcentrationId: yup
                .number()
                .notRequired()
                .transform((cv, ov) => {
                    return ov === '' || isNaN(ov) || !ov ? undefined : +cv;
                }),
            professionLevelId: yup
                .number()
                .notRequired()
                .transform((cv, ov) => {
                    return ov === '' || isNaN(ov) || !ov ? undefined : +cv;
                }),
            programAddOnTrackId: yup
                .number()
                .notRequired()
                .transform((cv, ov) => {
                    return ov === '' || isNaN(ov) || !ov ? undefined : +cv;
                }),
            programTitle: yup.string().required('Program Title is required'),
            programName: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            programAddress: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            programAddress2: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            programAddress3: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            programAddress4: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            programCity: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            programState: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            programZip: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            programCountry: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            programDegreeDiploma: yup.boolean().required().default(false),
            programDegreeCertificate: yup.boolean().required().default(false),
            programDegreeAssociate: yup.boolean().required().default(false),
            programDegreeBaccalaureate: yup.boolean().required().default(false),
            programDegreeMasters: yup.boolean().required().default(false),
            programDateFirstAccredited: yup.date().notRequired(),
            programDistance: yup.boolean().required().default(false),
            programMilitaryPersonnelOnly: yup.boolean().required().default(false),
            programWebSite: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            programOutcomesUrl: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            programDirectorFirstName: yup
                .string()
                .required('PD First Name is required')
                .typeError('PD First Name is required'),
            programDirectorLastName: yup
                .string()
                .required('PD Last Name is required')
                .typeError('PD Last Name is required'),
            programDirectorPhone: yup.string().required('PD Phone is required').typeError('PD Phone is required'),
            programDirectorExtension: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            programDirectorTitle: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            programDirectorCredentials: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            programDirectorSalutations: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            programDirectorEmail: yup.string().required('PD Email is required').typeError('PD Email is required'),
            deanFirstName: yup.string().when('deanNotAvailable', {
                is: false,
                then: yup.string().required('Dean First Name is required').typeError('Dean First Name is required'),
                otherwise: yup
                    .string()
                    .notRequired()
                    .transform((cv, ov) => {
                        return !ov ? undefined : cv;
                    }),
            }),
            deanLastName: yup.string().when('deanNotAvailable', {
                is: false,
                then: yup.string().required('Dean Last Name is required').typeError('Dean Last Name is required'),
                otherwise: yup
                    .string()
                    .notRequired()
                    .transform((cv, ov) => {
                        return !ov ? undefined : cv;
                    }),
            }),
            deanTitle: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            deanCredentials: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            deanSalutations: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            deanEmail: yup.string().when('deanNotAvailable', {
                is: false,
                then: yup.string().required('Dean Email is required').typeError('Dean Email is required'),
                otherwise: yup
                    .string()
                    .notRequired()
                    .transform((cv, ov) => {
                        return !ov ? undefined : cv;
                    }),
            }),
            ceofirstName: yup.string().required('CEO First Name is required').typeError('CEO First Name is required'),
            ceolastName: yup.string().required('CEO Last Name is required').typeError('CEO Last Name is required'),
            ceotitle: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            ceocredentials: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            ceosalutations: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            createdOn: yup.date().notRequired(),
            // createdBy?: string;
            modifiedOn: yup.date().notRequired(),
            // modifiedBy?: string;
            isMerged: yup.boolean().required().default(false),
            // mergedBy?: string;
            mergedByString: yup
                .string()
                .notRequired()
                .transform((cv, ov) => {
                    return !ov ? undefined : cv;
                }),
            mergedOn: yup
                .date()
                .notRequired()
                .transform((cv, ov) => {
                    return ov === '' || !ov ? undefined : cv;
                }),
            active: yup.boolean().required().default(true),
            programDirectorNotAvailable: yup.boolean().required().default(false),
            deanNotAvailable: yup.boolean().required().default(false),
            ceoNotAvailable: yup.boolean().required().default(false),
            // coaletterCc1?: string;
            // coaletterCc2?: string;
            // coaletterCc3?: string;
            transferFromProgramId: yup
                .number()
                .notRequired()
                .transform((cv, ov) => {
                    return ov === '' || isNaN(ov) || !ov ? undefined : +cv;
                }),
            transferFromInstitutionId: yup
                .number()
                .notRequired()
                .transform((cv, ov) => {
                    return ov === '' || isNaN(ov) || !ov ? undefined : +cv;
                }),
            transferToInstitutionId: yup
                .number()
                .notRequired()
                .transform((cv, ov) => {
                    return ov === '' || isNaN(ov) || !ov ? undefined : +cv;
                }),
            createdByString: yup.string().nullable().notRequired(),
            editedByString: yup.string().notRequired(),
            isCitationSectionComplete: yup.boolean().required().default(false),
            isDeanSectionComplete: yup.boolean().required().default(false),
            isCeoSectionComplete: yup.boolean().required().default(false),
            isInstitutionSectionComplete: yup.boolean().required().default(false),
            isProgramDirectorSectionComplete: yup.boolean().required().default(false),
            isProgramSectionComplete: yup.boolean().required().default(false),
            isRecommendationSectionComplete: yup.boolean().required().default(false),
        })
        .defined();

    const { handleSubmit, reset, watch, control, errors, setValue, formState } = useForm({
        validationSchema: recommendationSchema,
        defaultValues: {} as RecommendationDto,
    });

    const { recommendationId } = useParams<RecommendationParams>();
    const [transferFromInstitution, setTransferFromInstitution] = useState<Institution>();
    const [professionId, setProfessionId] = useState<number>();
    const [profession, setProfession] = useState<ProfessionDetail>();
    const [programId, setProgramId] = useState<number>();
    const [statusHistory, setStatusHistory] = useState<ProgramStatusHistory[]>();
    const [standards, setStandards] = useState<ProfessionStandardsDto[]>();
    const [dates, setDates] = useState<MeetingDateDto[]>();
    const [institutionTypes, setInstitutionTypes] = useState<InstitutionTypeDto[]>();
    const [auspices, setAuspices] = useState<AuspiceDto[]>();
    const [accreditors, setAccreditors] = useState<InstitutionalAccreditor[]>();
    const [selectedCitation, setSelectedCitation] = useState<CitationDto>();
    const [showCitationModal, setShowCitationModal] = useState<boolean>(false);
    const [showCitationRemoveModal, setShowCitationRemoveModal] = useState<boolean>(false);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [reloadCitations, setReloadCitations] = useState<boolean>(true);
    const [removeCitation, setRemoveCitation] = useState<CitationDto>();
    const [backToList, setBackToList] = useState<boolean>(false);
    const [isMergeCommand, setIsMergeCommand] = useState<boolean>(false);
    const [useInstitutionAddress, setUseInstitutionAddress] = useState<boolean>(false);

    const toggleCitationModal = () => setShowCitationModal(!showCitationModal);
    const toggleCitationRemoveModal = () => setShowCitationRemoveModal(!showCitationRemoveModal);
    const toggleRemoveModal = () => setShowRemoveModal(!showRemoveModal);

    const [satellites, setSatellites] = useState<RecommendationSatellite[]>();
    const [editSatellite, setEditSatellite] = useState<RecommendationSatellite>();
    const [reloadSatellites, setReloadSatellites] = useState<boolean>(true);
    const [showSatellite, setShowSatellite] = useState<boolean>(false);
    const toggleShowSatellite = () => setShowSatellite(!showSatellite);

    const [showChangeInst, setShowChangeInst] = useState<boolean>(false);
    const toggleShowChangeInst = () => setShowChangeInst(!showChangeInst);
    const [changeInstId, setChangeInstId] = useState<number>();
    const [changeNewInst, setChangeNewInst] = useState<boolean>(false);

    const location = useLocation();
    const isNew = location.pathname.indexOf('new') >= 0;

    const recId = +(recommendationId || 0);

    const programStatuses = [
        ProgramStatus.Continuing,
        ProgramStatus.Initial,
        ProgramStatus.Probation,
        ProgramStatus.Withdraw,
        ProgramStatus.Withhold,
    ];

    useEffect(() => {
        const loadRecommendation = () => {
            if (recId > 0) {
                recommendationService
                    .getById(recId)
                    .then((rec) => {
                        rec.effectiveDate = rec.effectiveDate
                            ? moment(rec.effectiveDate).format('YYYY-MM-DD')
                            : undefined;
                        rec.prdue = rec.prdue ? moment(rec.prdue).format('YYYY-MM-DD') : undefined;
                        rec.nextFullEval = rec.nextFullEval ? moment(rec.nextFullEval).format('YYYY-MM-DD') : undefined;
                        rec.programDateFirstAccredited = rec.programDateFirstAccredited
                            ? moment(rec.programDateFirstAccredited).format('YYYY-MM-DD')
                            : undefined;
                        if (rec.createdOn) {
                            rec.createdOn = moment.utc(rec.createdOn).local().toDate();
                        }

                        reset(rec);
                        setProfessionId(rec.programProfessionId);
                        setProgramId(rec.caahepprogramId);
                        //setSatellites((rec.satellites || []).sort((a, b) => (a.title || '').localeCompare(b.title || '')));

                        if (rec.transferFromInstitutionId) {
                            institutionService
                                .getInstitution(rec.transferFromInstitutionId)
                                .then((inst) => {
                                    setTransferFromInstitution(inst);
                                })
                                .catch(() => {
                                    toast.error('Error loading transfer from institution detail');
                                });
                        }
                    })
                    .catch(() => {
                        toast.error('Error loading recommendation');
                    });
            }
        };

        const loadMeetingDates = () => {
            systemAdministration
                .getMeetingDates()
                .then((meetings) => {
                    setDates(
                        meetings
                            .filter((m) => m.active === true)
                            .sort((a, b) => moment.utc(b.meetingDate).unix() - moment.utc(a.meetingDate).unix()),
                    );
                })
                .catch(() => {
                    toast.error('Error loading meeting dates');
                });
        };

        const loadInstitutionTypes = () => {
            institutionService
                .getTypes()
                .then((insTypes) => {
                    setInstitutionTypes(insTypes.filter((it) => it.isActive === true));
                })
                .catch(() => {
                    toast.error('Error loading institution types');
                });
        };

        const loadAuspices = () => {
            institutionService
                .getAuspice()
                .then((aus) => {
                    setAuspices(aus.filter((a) => a.isActive === true));
                })
                .catch(() => {
                    toast.error('Error loading auspices');
                });
        };

        const loadAccreditors = () => {
            systemAdministration
                .getInstitutionalAccreditors()
                .then((results) => {
                    setAccreditors([
                        ...results.filter((a) => a.isActive === true).sort((a, b) => a.title.localeCompare(b.title)),
                    ]);
                })
                .catch(() => {
                    toast.error('Error loading institutional accreditors');
                });
        };

        loadRecommendation();
        loadMeetingDates();
        loadInstitutionTypes();
        loadAuspices();
        loadAccreditors();
    }, [recId]);

    useEffect(() => {
        const loadCitations = () => {
            if (recId && reloadCitations) {
                setReloadCitations(false);
                recommendationService
                    .getCitationsByRecommendationId(recId)
                    .then((cits) => {
                        setCitations(cits);
                    })
                    .catch(() => {
                        toast.error('Error loading citations');
                    });
            }
        };

        loadCitations();
    }, [recId, reloadCitations]);

    useEffect(() => {
        const loadProfessionStandards = () => {
            if (professionId) {
                professionService
                    .getProfessionStandards(professionId)
                    .then((stds) => {
                        setStandards(stds.sort((a, b) => (a.standardYear || 0) - (b.standardYear || 0)));
                    })
                    .catch(() => {
                        toast.error('Error loading standards versions');
                    });
            }
        };

        const loadProfession = () => {
            if (professionId) {
                professionService
                    .getProfessionDetails(professionId)
                    .then((profession) => {
                        setProfession(profession);
                    })
                    .catch(() => {
                        toast.error('Error loading profession');
                    });
            }
        };

        loadProfessionStandards();
        loadProfession();
    }, [professionId]);

    useEffect(() => {
        const loadStatusHistory = () => {
            if (programId) {
                programService
                    .getProgramStatusHistoryByProgramId(programId)
                    .then((hist) => {
                        setStatusHistory(hist);
                    })
                    .catch(() => {
                        toast.error('Error loading standards versions');
                    });
            }
        };

        loadStatusHistory();
    }, [programId]);

    useEffect(() => {
        if (reloadSatellites) {
            setReloadSatellites(false);

            recommendationService
                .getSatellitesByRecommendationId(recId)
                .then((s) => {
                    setSatellites(s.sort((a, b) => (a.title || '').localeCompare(b.title || '')));
                })
                .catch(() => {
                    toast.error('Error loading satellites');
                });
        }
    }, [recId, reloadSatellites]);

    const recommendation = watch({ nest: true });
    const programDegreeAssociate = watch('programDegreeAssociate');
    const programDegreeBaccalaureate = watch('programDegreeBaccalaureate');
    const programDegreeCertificate = watch('programDegreeCertificate');
    const programDegreeDiploma = watch('programDegreeDiploma');
    const programDegreeMasters = watch('programDegreeMasters');

    const isDegreeSelected =
        programDegreeAssociate ||
        programDegreeBaccalaureate ||
        programDegreeCertificate ||
        programDegreeDiploma ||
        programDegreeMasters;

    const createdByInfo: UserInfo = recommendation.createdByString
        ? JSON.parse(recommendation.createdByString)
        : undefined;
    const modifiedByInfo: UserInfo = recommendation.modifiedByString
        ? JSON.parse(recommendation.modifiedByString)
        : undefined;
    const mergedByInfo: UserInfo = recommendation.mergedByString
        ? JSON.parse(recommendation.mergedByString)
        : undefined;

    const viewCitation = (citation: CitationDto) => {
        setSelectedCitation(citation);
        toggleCitationModal();
    };

    const newCitation = () => {
        setSelectedCitation({
            citationId: 0,
            isDeleted: false,
            programId: recommendation.caahepprogramId,
            recommendationId: recommendation.recommendationId,
            standardsDesignationId: -1,
            detail: [],
        });
        toggleCitationModal();
    };

    const deleteCitation = (citationId: number) => {
        const toastId = toast.info('Removing citation...');

        recommendationService
            .removeCitation(citationId)
            .then((cit) => {
                toast.update(toastId, {
                    render: 'Citation removed successfully',
                    type: 'success',
                });
                setReloadCitations(true);
                toggleCitationRemoveModal();
            })
            .catch(() => {
                toast.update(toastId, {
                    render: 'Error removing citation',
                    type: 'error',
                });
            });
    };

    const deleteRecommendation = (recommendationId: number) => {
        const toastId = toast.info('Deleting recommendation...');

        recommendationService
            .removeRecommendation(recommendationId)
            .then((result) => {
                toast.update(toastId, {
                    render: 'Recommendation deleted successfully',
                    type: 'success',
                });

                setBackToList(true);
            })
            .catch(() => {
                toast.update(toastId, {
                    render: 'Error deleting recommendation',
                    type: 'error',
                });
            });
    };

    const toggleSatellite = (s: RecommendationSatellite) => {
        const removeRestore = s.isActive === true ? 'Removing' : 'Restoring';
        const removeRestoreLc = s.isActive === true ? 'removing' : 'restoring';
        const removeRestorePt = s.isActive === true ? 'removed' : 'restored';

        const toastId = toast.info(`${removeRestore} satellite...`);

        recommendationService
            .saveSatellite({
                ...s,
                isActive: !s.isActive,
            })
            .then((s) => {
                toast.update(toastId, {
                    render: `Satellite ${removeRestorePt} successfully`,
                    type: 'success',
                });
                setReloadSatellites(true);
            })
            .catch(() => {
                toast.update(toastId, {
                    render: `Error ${removeRestoreLc} satellite`,
                    type: 'error',
                });
            });
    };

    const submitForm = (values: RecommendationDto) => {
        if (isMergeCommand) {
            const toastId = toast.info('Merging recommendation...');

            recommendationService
                .mergeRecommendation(values)
                .then((result) => {
                    toast.update(toastId, {
                        render: 'Recommendation merged successfully',
                        type: 'success',
                    });

                    setBackToList(true);
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error merging recommendation',
                        type: 'error',
                    });
                });
        } else {
            const toastId = toast.info('Saving recommendation...');

            recommendationService
                .updateRecommendation(values)
                .then((result) => {
                    toast.update(toastId, {
                        render: 'Recommendation saved successfully',
                        type: 'success',
                    });

                    result.effectiveDate = result.effectiveDate
                        ? moment(result.effectiveDate).format('YYYY-MM-DD')
                        : undefined;
                    result.prdue = result.prdue ? moment(result.prdue).format('YYYY-MM-DD') : undefined;
                    result.nextFullEval = result.nextFullEval
                        ? moment(result.nextFullEval).format('YYYY-MM-DD')
                        : undefined;
                    result.programDateFirstAccredited = result.programDateFirstAccredited
                        ? moment(result.programDateFirstAccredited).format('YYYY-MM-DD')
                        : undefined;

                    reset(result);
                    setBackToList(true);
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error saving recommendation',
                        type: 'error',
                    });
                });
        }
    };

    const canMerge = authService.hasPermission(AmsPermissionClaimType, 'recommendation');
    const cannotEditInstitution = !canMerge && +(recommendation.caahepinstitutionId || 0) > 0;

    const ableToMerge = (rec: RecommendationDto): boolean => {
        return (
            rec.isCeoSectionComplete &&
            rec.isCitationSectionComplete &&
            rec.isDeanSectionComplete &&
            rec.isInstitutionSectionComplete &&
            rec.isProgramDirectorSectionComplete &&
            rec.isProgramSectionComplete &&
            rec.isRecommendationSectionComplete
        );
    };

    const acceptAll = () => {
        setValue('isInstitutionSectionComplete', true);
        setValue('isCeoSectionComplete', true);
        setValue('isCitationSectionComplete', true);
        setValue('isDeanSectionComplete', true);
        setValue('isProgramDirectorSectionComplete', true);
        setValue('isProgramSectionComplete', true);
        setValue('isRecommendationSectionComplete', true);
    };

    const setEffectiveDate = (date) => {
        setValue('effectiveDate', moment(date).format('YYYY-MM-DD'));
    };

    useEffect(() => {
        if (useInstitutionAddress) {
            setValue('programAddress', recommendation.institutionAddress);
            setValue('programAddress2', recommendation.institutionAddress2);
            setValue('programAddress3', recommendation.institutionAddress3);
            setValue('programAddress4', recommendation.institutionAddress4);
            setValue('programCity', recommendation.institutionCity);
            setValue('programState', recommendation.institutionState);
            setValue('programZip', recommendation.institutionZip);
            setValue('programCountry', recommendation.institutionCountry);
        }
    }, [useInstitutionAddress]);

    const hasBeenMerged = (): boolean => {
        return recommendation.isMerged === true || recommendation.isMerged?.toString() === 'true';
    };

    const updateInstitution = (): void => {
        if (changeNewInst) {
            const toastId = toast.info('Updating recommendation...');
            recommendationService
                .updateInstitution(+recommendation.recommendationId)
                .then((rec) => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Recommendation updated successfully',
                    });
                    rec.effectiveDate = rec.effectiveDate ? moment(rec.effectiveDate).format('YYYY-MM-DD') : undefined;
                    rec.prdue = rec.prdue ? moment(rec.prdue).format('YYYY-MM-DD') : undefined;
                    rec.nextFullEval = rec.nextFullEval ? moment(rec.nextFullEval).format('YYYY-MM-DD') : undefined;
                    rec.programDateFirstAccredited = rec.programDateFirstAccredited
                        ? moment(rec.programDateFirstAccredited).format('YYYY-MM-DD')
                        : undefined;

                    reset(rec);
                    toggleShowChangeInst();
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error updating recommendation',
                    });
                });
        } else {
            if (changeInstId && !isNaN(changeInstId)) {
                const toastId = toast.info('Updating recommendation...');
                recommendationService
                    .updateInstitution(+recommendation.recommendationId, changeInstId)
                    .then((rec) => {
                        toast.update(toastId, {
                            type: 'success',
                            render: 'Recommendation updated successfully',
                        });
                        rec.effectiveDate = rec.effectiveDate
                            ? moment(rec.effectiveDate).format('YYYY-MM-DD')
                            : undefined;
                        rec.prdue = rec.prdue ? moment(rec.prdue).format('YYYY-MM-DD') : undefined;
                        rec.nextFullEval = rec.nextFullEval ? moment(rec.nextFullEval).format('YYYY-MM-DD') : undefined;
                        rec.programDateFirstAccredited = rec.programDateFirstAccredited
                            ? moment(rec.programDateFirstAccredited).format('YYYY-MM-DD')
                            : undefined;

                        reset(rec);
                        toggleShowChangeInst();
                    })
                    .catch(() => {
                        toast.update(toastId, {
                            type: 'error',
                            render: 'Error updating recommendation',
                        });
                    });
            } else {
                toast.warn('Institution ID must be a number');
            }
        }
    };

    if (recId === 0 || backToList) {
        return <Redirect to={`/recommendations/list`} />;
    } else {
        return (
            <>
                <PageTitle title={`Recommendation Detail`} />

                {(!recommendation || recommendation?.recommendationId === 0) && <Skeleton count={5} />}
                {(recommendation?.recommendationId || 0) > 0 && (
                    <Form onSubmit={handleSubmit(submitForm)}>
                        <Controller as={'input'} type={'hidden'} name={`recommendationId`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`citations`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`meetingDate`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`isMerged`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`active`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`createdByString`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`createdOn`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`modifiedByString`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`modifiedOn`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`mergedByString`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`mergedOn`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`caahepinstitutionId`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`caahepprogramId`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`programTitle`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`transferFromProgramId`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`transferFromInstitutionId`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`transferToInstitutionId`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`programProfessionId`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`professionTitle`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`programConcentrationId`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`professionLevelId`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`concentrationTitle`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`levelTitle`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`programAddOnTrackId`} control={control} />
                        <Controller as={'input'} type={'hidden'} name={`addOnTrackTitle`} control={control} />
                        <Row>
                            <Col>
                                <Card className={`bg-dark text-white`}>
                                    <CardBody className={`d-flex flex-column ribbon-box`}>
                                        <div>
                                            {recommendation.isMerged && (
                                                <Ribbon color={'success'}>
                                                    <span>{`This recommendation has been merged`}</span>
                                                </Ribbon>
                                            )}
                                            {!recommendation.isMerged && (
                                                <Ribbon color={'warning'}>
                                                    <span>{`This recommendation has not been merged`}</span>
                                                </Ribbon>
                                            )}
                                        </div>
                                        {recommendation.transferFromProgramId && (
                                            <span className={'card-title text-secondary'} style={{ lineHeight: '2em' }}>
                                                <Badge
                                                    className={`mr-2 text-dark`}
                                                    color={'warning'}
                                                >{`Transfer Sponsorship`}</Badge>
                                                <span>{`This recommendation is for program `}</span>
                                                <span
                                                    className={'font-weight-bold text-white'}
                                                >{`${recommendation.programTitle} `}</span>
                                                {!recommendation.caahepprogramId && (
                                                    <Badge
                                                        className={`text-dark`}
                                                        color={'warning'}
                                                    >{`New Program`}</Badge>
                                                )}
                                                {recommendation.caahepprogramId && (
                                                    <Badge
                                                        className={`text-dark`}
                                                        color={'info'}
                                                    >{`Existing Program`}</Badge>
                                                )}
                                                <span>{` and is transferring sponsorship from `}</span>
                                                <span
                                                    className={'font-weight-bold text-white'}
                                                >{`${transferFromInstitution?.title}`}</span>
                                                <span>{` to `}</span>
                                                <span
                                                    className={'font-weight-bold text-white'}
                                                >{`${recommendation.institutionTitle} `}</span>
                                                {(recommendation.transferToInstitutionId || 0) > 0 && (
                                                    <Badge
                                                        className={`text-dark`}
                                                        color={'info'}
                                                    >{`Existing Institution`}</Badge>
                                                )}
                                                {(recommendation.transferToInstitutionId || 0) === 0 && (
                                                    <Badge
                                                        className={`text-dark`}
                                                        color={'warning'}
                                                    >{`New Institution`}</Badge>
                                                )}
                                            </span>
                                        )}
                                        {!recommendation.transferFromProgramId && (
                                            <span className={'card-title text-secondary'}>
                                                <span>{`This recommendation is for program `}</span>
                                                <span
                                                    className={'font-weight-bold text-white'}
                                                >{`${recommendation.programTitle} `}</span>
                                                {!recommendation.caahepprogramId && (
                                                    <Badge
                                                        className={`text-dark`}
                                                        color={'warning'}
                                                    >{`New Program`}</Badge>
                                                )}
                                                {recommendation.caahepprogramId && (
                                                    <Badge
                                                        className={`text-dark`}
                                                        color={'info'}
                                                    >{`Existing Program`}</Badge>
                                                )}
                                                <span>{` at `}</span>
                                                <span
                                                    className={'font-weight-bold text-white'}
                                                >{`${recommendation.institutionTitle} `}</span>
                                                {+(recommendation.caahepinstitutionId || 0) > 0 && (
                                                    <Badge
                                                        className={`text-dark`}
                                                        color={'info'}
                                                    >{`Existing Institution`}</Badge>
                                                )}
                                                {+(recommendation.caahepinstitutionId || 0) === 0 && (
                                                    <Badge
                                                        className={`text-dark`}
                                                        color={'warning'}
                                                    >{`New Institution`}</Badge>
                                                )}
                                            </span>
                                        )}
                                        {createdByInfo?.name && (
                                            <Label className={`font-italic text-secondary`}>
                                                {`Created By: ${createdByInfo.name}`}
                                                {recommendation.createdOn &&
                                                    ` on ${moment
                                                        .utc(recommendation.createdOn)
                                                        .local()
                                                        .format('MM/DD/YYYY hh:mm A')}`}
                                            </Label>
                                        )}
                                        {modifiedByInfo?.name && (
                                            <Label className={`font-italic text-secondary`}>
                                                {`Last Edited By: ${modifiedByInfo.name}`}
                                                {recommendation.modifiedOn &&
                                                    ` on ${moment
                                                        .utc(recommendation.modifiedOn)
                                                        .local()
                                                        .format('MM/DD/YYYY hh:mm A')}`}
                                            </Label>
                                        )}
                                        {mergedByInfo?.name && (
                                            <Label className={`font-italic text-secondary`}>
                                                {`Merged By: ${mergedByInfo.name}`}
                                                {recommendation.mergedOn &&
                                                    ` on ${moment
                                                        .utc(recommendation.mergedOn)
                                                        .local()
                                                        .format('MM/DD/YYYY hh:mm A')}`}
                                            </Label>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader className={'bg-white'}>
                                        <h4>
                                            <div className={'d-flex align-items-center'}>
                                                <span className={'mr-3'}>{`Institution Details`}</span>
                                                {canMerge && (
                                                    <div className={'custom-control custom-checkbox mr-2'}>
                                                        <Controller
                                                            as={Input}
                                                            type={'checkbox'}
                                                            className={'custom-control-input'}
                                                            control={control}
                                                            name={'isInstitutionSectionComplete'}
                                                            id={'instSection'}
                                                            valueName={'checked'}
                                                        />
                                                        <Label
                                                            className={'custom-control-label font-weight-normal'}
                                                            for="instSection"
                                                        >{`Accept`}</Label>
                                                    </div>
                                                )}
                                            </div>
                                        </h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12} lg={6}>
                                                <FormGroup>
                                                    <Label>
                                                        <span>{`Institution ID`}</span>
                                                        {canMerge && (
                                                            <Button
                                                                className={'ml-3 btn-sm'}
                                                                type={'button'}
                                                                color={'primary'}
                                                                onClick={() => toggleShowChangeInst()}
                                                            >
                                                                <span>{`Change Institution`}</span>
                                                            </Button>
                                                        )}
                                                    </Label>
                                                    {recommendation.transferFromProgramId && (
                                                        <Input
                                                            type={'text'}
                                                            readOnly={true}
                                                            disabled={true}
                                                            value={
                                                                recommendation.transferToInstitutionId ||
                                                                'New Institution'
                                                            }
                                                        />
                                                    )}
                                                    {!recommendation.transferFromProgramId && (
                                                        <Input
                                                            type={'text'}
                                                            readOnly={true}
                                                            disabled={true}
                                                            value={
                                                                recommendation.caahepinstitutionId || 'New Institution'
                                                            }
                                                        />
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Institution Title`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        readOnly={cannotEditInstitution}
                                                        disabled={cannotEditInstitution}
                                                        name={'institutionTitle'}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Institution Type`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'select'}
                                                        control={control}
                                                        readOnly={cannotEditInstitution}
                                                        disabled={cannotEditInstitution}
                                                        name={'institutionTypeId'}
                                                    >
                                                        <option value={''}>{`Select...`}</option>
                                                        {institutionTypes &&
                                                            institutionTypes.map((it) => (
                                                                <option
                                                                    key={it.institutionTypeId}
                                                                    value={it.institutionTypeId}
                                                                >
                                                                    {it.title}
                                                                </option>
                                                            ))}
                                                    </Controller>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Auspice`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'select'}
                                                        control={control}
                                                        readOnly={cannotEditInstitution}
                                                        disabled={cannotEditInstitution}
                                                        name={'institutionAuspiceId'}
                                                    >
                                                        <option value={''}>{`Select...`}</option>
                                                        {auspices &&
                                                            auspices.map((a) => (
                                                                <option key={a.auspiceID} value={a.auspiceID}>
                                                                    {a.title}
                                                                </option>
                                                            ))}
                                                    </Controller>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Institutional Accreditor`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'select'}
                                                        control={control}
                                                        name={'institutionalAccreditorId'}
                                                    >
                                                        <option value={''}>{`Select...`}</option>
                                                        {accreditors &&
                                                            accreditors.map((a) => (
                                                                <option
                                                                    key={a.institutionalAccreditorId}
                                                                    value={a.institutionalAccreditorId}
                                                                >
                                                                    {a.title}
                                                                </option>
                                                            ))}
                                                    </Controller>
                                                </FormGroup>
                                                <Row>
                                                    <Col xs={12} lg={9}>
                                                        <FormGroup>
                                                            <Label>{`Phone`}</Label>
                                                            <Controller
                                                                as={Input}
                                                                type={'text'}
                                                                control={control}
                                                                readOnly={cannotEditInstitution}
                                                                disabled={cannotEditInstitution}
                                                                name={'institutionPhone'}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={12} lg={3}>
                                                        <FormGroup>
                                                            <Label>{`Extension`}</Label>
                                                            <Controller
                                                                as={Input}
                                                                type={'text'}
                                                                control={control}
                                                                readOnly={cannotEditInstitution}
                                                                disabled={cannotEditInstitution}
                                                                name={'institutionExtension'}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <FormGroup>
                                                    <Label>{`Website`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        readOnly={cannotEditInstitution}
                                                        disabled={cannotEditInstitution}
                                                        name={'institutionWebSite'}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <FormGroup>
                                                    <Label>{`Address`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        readOnly={cannotEditInstitution}
                                                        disabled={cannotEditInstitution}
                                                        name={'institutionAddress'}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Address 2`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        readOnly={cannotEditInstitution}
                                                        disabled={cannotEditInstitution}
                                                        name={'institutionAddress2'}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Address 3`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        readOnly={cannotEditInstitution}
                                                        disabled={cannotEditInstitution}
                                                        name={'institutionAddress3'}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Address 4`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        readOnly={cannotEditInstitution}
                                                        disabled={cannotEditInstitution}
                                                        name={'institutionAddress4'}
                                                    />
                                                </FormGroup>
                                                <Row>
                                                    <Col xs={12} lg={6}>
                                                        <FormGroup>
                                                            <Label>{`City`}</Label>
                                                            <Controller
                                                                as={Input}
                                                                type={'text'}
                                                                control={control}
                                                                readOnly={cannotEditInstitution}
                                                                disabled={cannotEditInstitution}
                                                                name={'institutionCity'}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={12} lg={6}>
                                                        <FormGroup>
                                                            <Label>{`State`}</Label>
                                                            {recommendation.institutionCountry ===
                                                                'United States of America' &&
                                                                States && (
                                                                    <Controller
                                                                        as={Input}
                                                                        type={'select'}
                                                                        control={control}
                                                                        readOnly={cannotEditInstitution}
                                                                        disabled={cannotEditInstitution}
                                                                        name={'institutionState'}
                                                                    >
                                                                        <option value={undefined}></option>
                                                                        {States.map((s) => (
                                                                            <option
                                                                                key={s.abbreviation}
                                                                                value={s.abbreviation}
                                                                            >
                                                                                {s.abbreviation}
                                                                            </option>
                                                                        ))}
                                                                    </Controller>
                                                                )}
                                                            {recommendation.institutionCountry !==
                                                                'United States of America' && (
                                                                <Controller
                                                                    as={Input}
                                                                    type={'text'}
                                                                    control={control}
                                                                    readOnly={cannotEditInstitution}
                                                                    disabled={cannotEditInstitution}
                                                                    name={'institutionState'}
                                                                />
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} lg={6}>
                                                        <FormGroup>
                                                            <Label>{`Zip / Postal Code`}</Label>
                                                            <Controller
                                                                as={Input}
                                                                type={'text'}
                                                                control={control}
                                                                readOnly={cannotEditInstitution}
                                                                disabled={cannotEditInstitution}
                                                                name={'institutionZip'}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={12} lg={6}>
                                                        <FormGroup>
                                                            <Label>{`Country`}</Label>
                                                            <Controller
                                                                as={Input}
                                                                type={'select'}
                                                                control={control}
                                                                readOnly={cannotEditInstitution}
                                                                disabled={cannotEditInstitution}
                                                                name={'institutionCountry'}
                                                            >
                                                                <option value={undefined}></option>
                                                                {Countries.map((c) => (
                                                                    <option key={c} value={c}>
                                                                        {c}
                                                                    </option>
                                                                ))}
                                                            </Controller>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={12}>
                                <CardHeader className={'bg-white'}>
                                    <h4>
                                        <div className={'d-flex align-items-center'}>
                                            <span className={'mr-3'}>{`Program Details`}</span>
                                            <div className={'custom-control custom-checkbox mr-2'}>
                                                <Input
                                                    type={'checkbox'}
                                                    className={'custom-control-input'}
                                                    id={'useInstitutionAddress'}
                                                    checked={useInstitutionAddress}
                                                    onChange={(e) => {
                                                        setUseInstitutionAddress(e.target.checked);
                                                    }}
                                                />
                                                <Label
                                                    className={'custom-control-label font-weight-normal'}
                                                    for="useInstitutionAddress"
                                                >{`Use Institution Address`}</Label>
                                            </div>
                                            {canMerge && (
                                                <div className={'custom-control custom-checkbox mr-2'}>
                                                    <Controller
                                                        as={Input}
                                                        type={'checkbox'}
                                                        className={'custom-control-input'}
                                                        control={control}
                                                        name={'isProgramSectionComplete'}
                                                        id={'progSection'}
                                                        valueName={'checked'}
                                                    />
                                                    <Label
                                                        className={'custom-control-label font-weight-normal'}
                                                        for="progSection"
                                                    >{`Accept`}</Label>
                                                </div>
                                            )}
                                        </div>
                                    </h4>
                                </CardHeader>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12} lg={6}>
                                                <FormGroup>
                                                    <Label>{`Profession`}</Label>
                                                    <div>
                                                        <span>{recommendation.professionTitle}</span>
                                                    </div>
                                                </FormGroup>
                                                {recommendation.concentrationTitle && (
                                                    <FormGroup>
                                                        <Label>{`Concentration`}</Label>
                                                        <div>
                                                            <span>{recommendation.concentrationTitle}</span>
                                                        </div>
                                                    </FormGroup>
                                                )}
                                                {recommendation.levelTitle && (
                                                    <FormGroup>
                                                        <Label>{`Level`}</Label>
                                                        <div>
                                                            <span>{recommendation.levelTitle}</span>
                                                        </div>
                                                    </FormGroup>
                                                )}
                                                {recommendation.addOnTrackTitle && (
                                                    <FormGroup>
                                                        <Label>{`Add-On Track`}</Label>
                                                        <div>
                                                            <span>{recommendation.addOnTrackTitle}</span>
                                                        </div>
                                                    </FormGroup>
                                                )}
                                                <FormGroup>
                                                    <Label>{`CAAHEP Program Title`}</Label>
                                                    <div>
                                                        <span>{recommendation.programTitle}</span>
                                                    </div>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Program Name`}</Label>
                                                    <div>
                                                        <Controller
                                                            as={Input}
                                                            type={'text'}
                                                            className={'form-control'}
                                                            control={control}
                                                            name={'programName'}
                                                        />{' '}
                                                    </div>
                                                </FormGroup>
                                                {!!profession && (
                                                    <FormGroup>
                                                        <Label>{`Degree`}</Label>
                                                        <div className={'d-flex'}>
                                                            <div className={'custom-control custom-checkbox mr-2'}>
                                                                <Controller
                                                                    as={Input}
                                                                    type={'checkbox'}
                                                                    className={'custom-control-input'}
                                                                    control={control}
                                                                    name={'programDegreeAssociate'}
                                                                    id={'associate'}
                                                                    valueName={'checked'}
                                                                    disabled={
                                                                        (!programDegreeAssociate &&
                                                                            profession.isOneDegreePerProgram &&
                                                                            isDegreeSelected) ||
                                                                        false
                                                                    }
                                                                />
                                                                <Label
                                                                    className={
                                                                        'custom-control-label font-weight-normal'
                                                                    }
                                                                    for="associate"
                                                                >{`Associate`}</Label>
                                                            </div>
                                                            <div className={'custom-control custom-checkbox mr-2'}>
                                                                <Controller
                                                                    as={Input}
                                                                    type={'checkbox'}
                                                                    className={'custom-control-input'}
                                                                    control={control}
                                                                    name={'programDegreeCertificate'}
                                                                    id={'certificate'}
                                                                    valueName={'checked'}
                                                                    disabled={
                                                                        (!programDegreeCertificate &&
                                                                            profession.isOneDegreePerProgram &&
                                                                            isDegreeSelected) ||
                                                                        false
                                                                    }
                                                                />
                                                                <Label
                                                                    className={
                                                                        'custom-control-label font-weight-normal'
                                                                    }
                                                                    for="certificate"
                                                                >{`Certificate`}</Label>
                                                            </div>
                                                            <div className={'custom-control custom-checkbox mr-2'}>
                                                                <Controller
                                                                    as={Input}
                                                                    type={'checkbox'}
                                                                    className={'custom-control-input'}
                                                                    control={control}
                                                                    name={'programDegreeDiploma'}
                                                                    id={'diploma'}
                                                                    valueName={'checked'}
                                                                    disabled={
                                                                        (!programDegreeDiploma &&
                                                                            profession.isOneDegreePerProgram &&
                                                                            isDegreeSelected) ||
                                                                        false
                                                                    }
                                                                />
                                                                <Label
                                                                    className={
                                                                        'custom-control-label font-weight-normal'
                                                                    }
                                                                    for="diploma"
                                                                >{`Diploma`}</Label>
                                                            </div>
                                                            <div className={'custom-control custom-checkbox mr-2'}>
                                                                <Controller
                                                                    as={Input}
                                                                    type={'checkbox'}
                                                                    className={'custom-control-input'}
                                                                    control={control}
                                                                    name={'programDegreeBaccalaureate'}
                                                                    id={'baccalaureate'}
                                                                    valueName={'checked'}
                                                                    disabled={
                                                                        (!programDegreeBaccalaureate &&
                                                                            profession.isOneDegreePerProgram &&
                                                                            isDegreeSelected) ||
                                                                        false
                                                                    }
                                                                />
                                                                <Label
                                                                    className={
                                                                        'custom-control-label font-weight-normal'
                                                                    }
                                                                    for="baccalaureate"
                                                                >{`Baccalaureate`}</Label>
                                                            </div>
                                                            <div className={'custom-control custom-checkbox'}>
                                                                <Controller
                                                                    as={Input}
                                                                    type={'checkbox'}
                                                                    className={'custom-control-input'}
                                                                    control={control}
                                                                    name={'programDegreeMasters'}
                                                                    id={'masters'}
                                                                    valueName={'checked'}
                                                                    disabled={
                                                                        (!programDegreeMasters &&
                                                                            profession.isOneDegreePerProgram &&
                                                                            isDegreeSelected) ||
                                                                        false
                                                                    }
                                                                />
                                                                <Label
                                                                    className={
                                                                        'custom-control-label font-weight-normal'
                                                                    }
                                                                    for="masters"
                                                                >{`Masters`}</Label>
                                                            </div>
                                                        </div>
                                                    </FormGroup>
                                                )}
                                                <FormGroup>
                                                    <Label>{`Date First Accredited`}</Label>
                                                    {canMerge && (
                                                        <Controller
                                                            as={Input}
                                                            type={'date'}
                                                            control={control}
                                                            name={'programDateFirstAccredited'}
                                                        />
                                                    )}
                                                    {!canMerge && (
                                                        <>
                                                            <Controller
                                                                as={Input}
                                                                type={'hidden'}
                                                                control={control}
                                                                name={'programDateFirstAccredited'}
                                                            />
                                                            <span className={'d-block'}>
                                                                {moment
                                                                    .utc(recommendation.programDateFirstAccredited)
                                                                    .format('MM/DD/YYYY')}
                                                            </span>
                                                        </>
                                                    )}
                                                    {errors?.programDateFirstAccredited?.message && (
                                                        <div className={'mt-1'}>
                                                            <span className={'text-danger'}>
                                                                {errors.programDateFirstAccredited.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <div className={'d-flex'}>
                                                        <div className={'custom-control custom-checkbox mr-2'}>
                                                            <Controller
                                                                as={Input}
                                                                type={'checkbox'}
                                                                className={'custom-control-input'}
                                                                control={control}
                                                                name={'programDistance'}
                                                                id={'distance'}
                                                                valueName={'checked'}
                                                            />
                                                            <Label
                                                                className={'custom-control-label'}
                                                                for="distance"
                                                            >{`Distance?`}</Label>
                                                        </div>
                                                        <div className={'custom-control custom-checkbox'}>
                                                            <Controller
                                                                as={Input}
                                                                type={'checkbox'}
                                                                className={'custom-control-input'}
                                                                control={control}
                                                                name={'programMilitaryPersonnelOnly'}
                                                                id={'military'}
                                                                valueName={'checked'}
                                                            />
                                                            <Label
                                                                className={'custom-control-label'}
                                                                for="military"
                                                            >{`Military?`}</Label>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Program Website`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        className={'form-control'}
                                                        control={control}
                                                        name={'programWebSite'}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Outcomes Url`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        className={'form-control'}
                                                        control={control}
                                                        name={'programOutcomesUrl'}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Satellites`}</Label>
                                                    {satellites && satellites.length > 0 && (
                                                        <Row>
                                                            {satellites.map((s) => (
                                                                <Col key={s.recommendationSatelliteId} xs={12} lg={6}>
                                                                    <Card>
                                                                        <CardBody>
                                                                            <span>{`${s.title}${
                                                                                s.city || s.state
                                                                                    ? ` (${s.city || ''}${
                                                                                          s.city
                                                                                              ? `, ${s.state}`
                                                                                              : `${s.state || ''}`
                                                                                      })`
                                                                                    : ''
                                                                            }`}</span>
                                                                            {s.isActive === false && (
                                                                                <Alert
                                                                                    color={'danger'}
                                                                                    className={'text-center mt-2 mb-0'}
                                                                                >
                                                                                    <span>{`Will be removed when the recommendation is merged.`}</span>
                                                                                </Alert>
                                                                            )}
                                                                        </CardBody>
                                                                        {!hasBeenMerged() && (
                                                                            <CardFooter className={'p-0'}>
                                                                                <ButtonGroup className={'d-flex'}>
                                                                                    {s.isActive === true && (
                                                                                        <>
                                                                                            <Button
                                                                                                type={'button'}
                                                                                                className={'btn-sm'}
                                                                                                color={'white'}
                                                                                                onClick={() => {
                                                                                                    setEditSatellite({
                                                                                                        ...s,
                                                                                                    });
                                                                                                    toggleShowSatellite();
                                                                                                }}
                                                                                            >
                                                                                                <i
                                                                                                    className={
                                                                                                        'mdi mdi-pencil'
                                                                                                    }
                                                                                                />
                                                                                                <span
                                                                                                    className={'ml-1'}
                                                                                                >{`Edit`}</span>
                                                                                            </Button>
                                                                                            <Button
                                                                                                type={'button'}
                                                                                                className={'btn-sm'}
                                                                                                color={'white'}
                                                                                                onClick={() =>
                                                                                                    toggleSatellite(s)
                                                                                                }
                                                                                            >
                                                                                                <i
                                                                                                    className={
                                                                                                        'mdi mdi-delete'
                                                                                                    }
                                                                                                />
                                                                                                <span
                                                                                                    className={'ml-1'}
                                                                                                >{`Remove`}</span>
                                                                                            </Button>
                                                                                        </>
                                                                                    )}
                                                                                </ButtonGroup>
                                                                                {s.isActive === false && (
                                                                                    <Button
                                                                                        type={'button'}
                                                                                        color={'white'}
                                                                                        className={'btn-sm w-100'}
                                                                                        onClick={() =>
                                                                                            toggleSatellite(s)
                                                                                        }
                                                                                    >
                                                                                        <i
                                                                                            className={
                                                                                                'mdi mdi-history'
                                                                                            }
                                                                                        />
                                                                                        <span
                                                                                            className={'ml-1'}
                                                                                        >{`Restore`}</span>
                                                                                    </Button>
                                                                                )}
                                                                            </CardFooter>
                                                                        )}
                                                                    </Card>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    )}
                                                    <div className={'d-flex flex-column'}>
                                                        {satellites && satellites.length === 0 && (
                                                            <div>
                                                                <Label>{`No Satellites`}</Label>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {!hasBeenMerged() && (
                                                        <Row>
                                                            <Col xs={12}>
                                                                <Button
                                                                    className={'btn-sm w-100'}
                                                                    color={'primary'}
                                                                    onClick={() => {
                                                                        setEditSatellite({
                                                                            isActive: true,
                                                                            recommendationId: recId,
                                                                            recommendationSatelliteId: 0,
                                                                        });
                                                                        toggleShowSatellite();
                                                                    }}
                                                                >
                                                                    <i className={'mdi mdi-plus'} />
                                                                    <span className={'ml-1'}>{`New Satellite`}</span>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <FormGroup>
                                                    <Label>{`Address`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'programAddress'}
                                                    />
                                                    {errors?.programAddress?.message && (
                                                        <div className={'mt-1'}>
                                                            <span className={'text-danger'}>
                                                                {errors.programAddress.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Address 2`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'programAddress2'}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Address 3`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'programAddress3'}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Address 4`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'programAddress4'}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`City`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'programCity'}
                                                    />
                                                    {errors?.programCity?.message && (
                                                        <div className={'mt-1'}>
                                                            <span className={'text-danger'}>
                                                                {errors.programCity.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`State`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'select'}
                                                        control={control}
                                                        name={'programState'}
                                                    >
                                                        <option value={undefined}></option>
                                                        {States.map((s) => (
                                                            <option key={s.abbreviation} value={s.abbreviation}>
                                                                {s.abbreviation}
                                                            </option>
                                                        ))}
                                                    </Controller>
                                                    {errors?.programState?.message && (
                                                        <div className={'mt-1'}>
                                                            <span className={'text-danger'}>
                                                                {errors.programState.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Zip / Postal Code`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'programZip'}
                                                    />
                                                    {errors?.programZip?.message && (
                                                        <div className={'mt-1'}>
                                                            <span className={'text-danger'}>
                                                                {errors.programZip.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Country`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'select'}
                                                        control={control}
                                                        name={'programCountry'}
                                                    >
                                                        <option value={''}>{`Select...`}</option>
                                                        {Countries.map((c) => (
                                                            <option key={c} value={c}>
                                                                {c}
                                                            </option>
                                                        ))}
                                                    </Controller>
                                                    {errors?.programCountry?.message && (
                                                        <div className={'mt-1'}>
                                                            <span className={'text-danger'}>
                                                                {errors.programCountry.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            {(recommendation.caahepprogramId || 0) > 0 && (
                                <Col xs={12} lg={6}>
                                    <Card>
                                        <CardHeader className={'bg-white'}>
                                            <h4>{`Program Status History`}</h4>
                                        </CardHeader>
                                        <div className={'table-responsive'}>
                                            <table className={'table mb-0'}>
                                                <thead>
                                                    <tr>
                                                        <th>{`Status`}</th>
                                                        <th>{`Effective Date`}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {statusHistory &&
                                                        statusHistory.map((sh) => (
                                                            <tr key={sh.historyId}>
                                                                <td>{ProgramStatus[sh.statusId]}</td>
                                                                <td>{moment(sh.effectiveDate).format('MM/DD/YYYY')}</td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card>
                                </Col>
                            )}
                            <Col xs={12} lg={6}>
                                <Card>
                                    <CardHeader className={'bg-white'}>
                                        <h4>
                                            <div className={'d-flex align-items-center'}>
                                                <span className={'mr-3'}>{`Recommendation Details`}</span>
                                                {canMerge && (
                                                    <div className={'custom-control custom-checkbox mr-2'}>
                                                        <Controller
                                                            as={Input}
                                                            type={'checkbox'}
                                                            className={'custom-control-input'}
                                                            control={control}
                                                            name={'isRecommendationSectionComplete'}
                                                            id={'recSection'}
                                                            valueName={'checked'}
                                                        />
                                                        <Label
                                                            className={'custom-control-label font-weight-normal'}
                                                            for="recSection"
                                                        >{`Accept`}</Label>
                                                    </div>
                                                )}
                                            </div>
                                        </h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12} lg={6}>
                                                <FormGroup>
                                                    <Label>{`Standards Version`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'select'}
                                                        control={control}
                                                        name={'professionStandardId'}
                                                    >
                                                        {standards &&
                                                            standards.map((s) => (
                                                                <option
                                                                    key={s.professionStandardId}
                                                                    value={s.professionStandardId}
                                                                >
                                                                    {s.standardYear}
                                                                </option>
                                                            ))}
                                                    </Controller>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Recommended Status`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'select'}
                                                        control={control}
                                                        name={'statusId'}
                                                    >
                                                        {programStatuses.map((s) => (
                                                            <option key={s} value={s}>
                                                                {ProgramStatus[s]}
                                                            </option>
                                                        ))}
                                                    </Controller>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`CAAHEP Meeting`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'select'}
                                                        control={control}
                                                        name={'meetingDateId'}
                                                        onChange={([e]) =>
                                                            setEffectiveDate(
                                                                dates?.find((d) => d.meetingDateId === +e.target.value)
                                                                    ?.meetingDate,
                                                            )
                                                        }
                                                    >
                                                        {dates &&
                                                            dates.map((d) => (
                                                                <option key={d.meetingDateId} value={d.meetingDateId}>
                                                                    {moment(d.meetingDate).format('MM/DD/YYYY')}
                                                                </option>
                                                            ))}
                                                    </Controller>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <FormGroup>
                                                    <Label>{`Effective Date`}</Label>
                                                    {canMerge && (
                                                        <Controller
                                                            as={Input}
                                                            type={'date'}
                                                            control={control}
                                                            name={'effectiveDate'}
                                                        />
                                                    )}
                                                    {!canMerge && (
                                                        <>
                                                            <Controller
                                                                as={Input}
                                                                type={'hidden'}
                                                                control={control}
                                                                name={'effectiveDate'}
                                                            />
                                                            <span className={'d-block'}>
                                                                {moment
                                                                    .utc(recommendation.effectiveDate)
                                                                    .format('MM/DD/YYYY')}
                                                            </span>
                                                        </>
                                                    )}
                                                    {errors?.effectiveDate?.message && (
                                                        <div className={'mt-1'}>
                                                            <span className={'text-danger'}>
                                                                {errors.effectiveDate.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Next Full Evaluation`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'date'}
                                                        control={control}
                                                        name={'nextFullEval'}
                                                    />
                                                    {errors?.nextFullEval?.message && (
                                                        <div className={'mt-1'}>
                                                            <span className={'text-danger'}>
                                                                {errors.nextFullEval.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Card>
                                    <CardHeader className={'bg-white'}>
                                        <h4>
                                            <div className={'d-flex justify-content-between'}>
                                                <div className={'d-flex align-items-center'}>
                                                    <span className={'mr-3'}>{`Citations`}</span>
                                                    {canMerge && (
                                                        <div className={'custom-control custom-checkbox mr-2'}>
                                                            <Controller
                                                                as={Input}
                                                                type={'checkbox'}
                                                                className={'custom-control-input'}
                                                                control={control}
                                                                name={'isCitationSectionComplete'}
                                                                id={'citSection'}
                                                                valueName={'checked'}
                                                            />
                                                            <Label
                                                                className={'custom-control-label font-weight-normal'}
                                                                for="citSection"
                                                            >{`Accept`}</Label>
                                                        </div>
                                                    )}
                                                </div>
                                                {(recommendation.isMerged === false ||
                                                    recommendation.isMerged.toString() === 'false') && (
                                                    <Button
                                                        type={'button'}
                                                        color={'primary'}
                                                        onClick={() => newCitation()}
                                                    >
                                                        {`New Citation`}
                                                    </Button>
                                                )}
                                            </div>
                                        </h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12} md={6} xl={4}>
                                                <FormGroup>
                                                    <Label>{`Progress Report Due Date`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'date'}
                                                        control={control}
                                                        name={'prdue'}
                                                    />
                                                    {errors?.prdue?.message && (
                                                        <div className={'mt-1'}>
                                                            <span className={'text-danger'}>
                                                                {errors.prdue.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <div className={'table-responsive'}>
                                        <table className={'table mb-0'}>
                                            <thead>
                                                <tr>
                                                    <th>{`Designation Number`}</th>
                                                    <th>{`Designation Title`}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {citations &&
                                                    citations.length > 0 &&
                                                    citations.map((c) => (
                                                        <tr key={c.citationId}>
                                                            <td>{c.designationNumber}</td>
                                                            <td>{c.designationTitle}</td>
                                                            <td className={'text-right'}>
                                                                <ButtonGroup>
                                                                    <Button
                                                                        type={'button'}
                                                                        color={'primary'}
                                                                        onClick={() => viewCitation(c)}
                                                                    >
                                                                        {`Edit`}
                                                                    </Button>
                                                                    <Button
                                                                        type={'button'}
                                                                        color={'danger'}
                                                                        onClick={() => {
                                                                            setRemoveCitation(c);
                                                                            toggleCitationRemoveModal();
                                                                        }}
                                                                    >
                                                                        {`Remove`}
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                {citations && citations.length === 0 && (
                                                    <tr>
                                                        <td colSpan={3}>{`No Citations`}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Card>
                                    <CardHeader className={'bg-white'}>
                                        <h4>
                                            <div className={'d-flex align-items-center'}>
                                                <span className={'mr-3'}>{`Program Director`}</span>
                                                {canMerge && (
                                                    <div className={'custom-control custom-checkbox mr-2'}>
                                                        <Controller
                                                            as={Input}
                                                            type={'checkbox'}
                                                            className={'custom-control-input'}
                                                            control={control}
                                                            name={'isProgramDirectorSectionComplete'}
                                                            id={'pdSection'}
                                                            valueName={'checked'}
                                                        />
                                                        <Label
                                                            className={'custom-control-label font-weight-normal'}
                                                            for="pdSection"
                                                        >{`Accept`}</Label>
                                                    </div>
                                                )}
                                            </div>
                                        </h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12} lg={6}>
                                                <FormGroup>
                                                    <Label>{`First Name`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'programDirectorFirstName'}
                                                    />
                                                    {errors?.programDirectorFirstName?.message && (
                                                        <div className={'mt-1'}>
                                                            <span className={'text-danger'}>
                                                                {errors.programDirectorFirstName.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Credentials`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'programDirectorCredentials'}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Job Title`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'programDirectorTitle'}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Phone`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'programDirectorPhone'}
                                                    />
                                                    {errors?.programDirectorPhone?.message && (
                                                        <div className={'mt-1'}>
                                                            <span className={'text-danger'}>
                                                                {errors.programDirectorPhone.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <FormGroup>
                                                    <Label>{`Last Name`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'programDirectorLastName'}
                                                    />
                                                    {errors?.programDirectorLastName?.message && (
                                                        <div className={'mt-1'}>
                                                            <span className={'text-danger'}>
                                                                {errors.programDirectorLastName.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Salutations`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'programDirectorSalutations'}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Email`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'programDirectorEmail'}
                                                    />
                                                    {errors?.programDirectorEmail?.message && (
                                                        <div className={'mt-1'}>
                                                            <span className={'text-danger'}>
                                                                {errors.programDirectorEmail.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Extension`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'programDirectorExtension'}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Card>
                                    <CardHeader className={'bg-white'}>
                                        <h4>
                                            <div className={'d-flex align-items-center'}>
                                                <span className={'mr-3'}>{`Dean`}</span>
                                                <div className={'custom-control custom-checkbox mr-2'}>
                                                    <Controller
                                                        as={Input}
                                                        type={'checkbox'}
                                                        className={'custom-control-input'}
                                                        control={control}
                                                        name={'deanNotAvailable'}
                                                        id={'dna'}
                                                        valueName={'checked'}
                                                    />
                                                    <Label
                                                        className={'custom-control-label font-weight-normal'}
                                                        for="dna"
                                                    >{`Dean Not Available`}</Label>
                                                </div>
                                                {canMerge && (
                                                    <div className={'custom-control custom-checkbox mr-2'}>
                                                        <Controller
                                                            as={Input}
                                                            type={'checkbox'}
                                                            className={'custom-control-input'}
                                                            control={control}
                                                            name={'isDeanSectionComplete'}
                                                            id={'deanSection'}
                                                            valueName={'checked'}
                                                        />
                                                        <Label
                                                            className={'custom-control-label font-weight-normal'}
                                                            for="deanSection"
                                                        >{`Accept`}</Label>
                                                    </div>
                                                )}
                                            </div>
                                        </h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12} lg={6}>
                                                <FormGroup>
                                                    <Label>{`First Name`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'deanFirstName'}
                                                    />
                                                    {errors?.deanFirstName?.message && (
                                                        <div className={'mt-1'}>
                                                            <span className={'text-danger'}>
                                                                {errors.deanFirstName.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Credentials`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'deanCredentials'}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Job Title`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'deanTitle'}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <FormGroup>
                                                    <Label>{`Last Name`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'deanLastName'}
                                                    />
                                                    {errors?.deanLastName?.message && (
                                                        <div className={'mt-1'}>
                                                            <span className={'text-danger'}>
                                                                {errors.deanLastName.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Salutations`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'deanSalutations'}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Email`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'deanEmail'}
                                                    />
                                                    {errors?.deanEmail?.message && (
                                                        <div className={'mt-1'}>
                                                            <span className={'text-danger'}>
                                                                {errors.deanEmail.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Card>
                                    <CardHeader className={'bg-white'}>
                                        <h4>
                                            <div className={'d-flex align-items-center'}>
                                                <span className={'mr-3'}>{`President / CEO`}</span>
                                                {canMerge && (
                                                    <div className={'custom-control custom-checkbox mr-2'}>
                                                        <Controller
                                                            as={Input}
                                                            type={'checkbox'}
                                                            className={'custom-control-input'}
                                                            control={control}
                                                            name={'isCeoSectionComplete'}
                                                            id={'ceoSection'}
                                                            valueName={'checked'}
                                                        />
                                                        <Label
                                                            className={'custom-control-label font-weight-normal'}
                                                            for="ceoSection"
                                                        >{`Accept`}</Label>
                                                    </div>
                                                )}
                                            </div>
                                        </h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12} lg={6}>
                                                <FormGroup>
                                                    <Label>{`First Name`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'ceofirstName'}
                                                    />
                                                    {errors?.ceofirstName?.message && (
                                                        <div className={'mt-1'}>
                                                            <span className={'text-danger'}>
                                                                {errors.ceofirstName.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Credentials`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'ceocredentials'}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Job Title`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'ceotitle'}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <FormGroup>
                                                    <Label>{`Last Name`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'ceolastName'}
                                                    />
                                                    {errors?.ceolastName?.message && (
                                                        <div className={'mt-1'}>
                                                            <span className={'text-danger'}>
                                                                {errors.ceolastName.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Salutations`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'ceosalutations'}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>{`Email`}</Label>
                                                    <Controller
                                                        as={Input}
                                                        type={'text'}
                                                        control={control}
                                                        name={'ceoemail'}
                                                    />
                                                    {errors?.ceoemail?.message && (
                                                        <div className={'mt-1'}>
                                                            <span className={'text-danger'}>
                                                                {errors.ceoemail.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <CardFooter>
                                        {!formState.isValid && formState.isSubmitted && (
                                            <Row>
                                                <Col>
                                                    {errors && (
                                                        <Alert color={'danger'}>
                                                            <div className="d-flex flex-column">
                                                                <Label
                                                                    className={'text-danger'}
                                                                >{`There were form errors, please resolve and resubmit:`}</Label>
                                                                {Object.keys(errors).map((fieldName) => (
                                                                    <>
                                                                        {errors[fieldName]?.message && (
                                                                            <Label className={'text-danger'}>
                                                                                {errors[fieldName]?.message}
                                                                            </Label>
                                                                        )}
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </Alert>
                                                    )}
                                                </Col>
                                            </Row>
                                        )}
                                        <div className={'d-flex justify-content-between align-items-center'}>
                                            <div>
                                                {canMerge && (
                                                    <>
                                                        {!hasBeenMerged() && (
                                                            <>
                                                                <Button
                                                                    color={'dark'}
                                                                    type={'button'}
                                                                    className={'mr-3'}
                                                                    onClick={() => acceptAll()}
                                                                >
                                                                    {`Accept All`}
                                                                </Button>
                                                                <Button
                                                                    color={'primary'}
                                                                    type={'submit'}
                                                                    className={'mr-3'}
                                                                    onClick={() => setIsMergeCommand(true)}
                                                                    disabled={!ableToMerge(recommendation)}
                                                                >
                                                                    {`Merge Recommendation`}
                                                                </Button>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                {!hasBeenMerged() && (
                                                    <Button
                                                        color={'info'}
                                                        type={'submit'}
                                                        onClick={() => setIsMergeCommand(false)}
                                                    >
                                                        {`Save Recommendation`}
                                                    </Button>
                                                )}
                                            </div>
                                            {!hasBeenMerged() && (
                                                <Button
                                                    color={`danger`}
                                                    type={'button'}
                                                    onClick={() => toggleRemoveModal()}
                                                >
                                                    {`${isNew ? 'Cancel' : 'Delete'} Recommendation`}
                                                </Button>
                                            )}
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                )}

                {selectedCitation && recId && recommendation.programProfessionId && (
                    <Modal isOpen={showCitationModal} toggle={toggleCitationModal} size={'lg'}>
                        <CitationForm
                            citation={selectedCitation}
                            professionId={recommendation.programProfessionId}
                            onCancel={toggleCitationModal}
                            onSave={() => {
                                setReloadCitations(true);
                                toggleCitationModal();
                            }}
                        />
                    </Modal>
                )}

                {removeCitation && (
                    <Modal isOpen={showCitationRemoveModal} toggle={toggleCitationRemoveModal}>
                        <ModalHeader>
                            <h4>{`Remove Citation`}</h4>
                        </ModalHeader>
                        <ModalBody>
                            <p>
                                {`To confirm you would like to remove the citation for Designation Number ${removeCitation.designationNumber}, please click the 'Confirm' button below.`}
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                type={'button'}
                                color={'primary'}
                                onClick={() => deleteCitation(removeCitation.citationId)}
                            >
                                {`Confirm`}
                            </Button>
                            <Button
                                type={'button'}
                                color={'secondary'}
                                className={'ml-3'}
                                onClick={() => toggleCitationRemoveModal()}
                            >
                                {`Cancel`}
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}

                <Modal isOpen={showRemoveModal} toggle={toggleRemoveModal}>
                    <ModalHeader>
                        <h4>{`Delete Recommendation`}</h4>
                    </ModalHeader>
                    <ModalBody>
                        <p>
                            {`To confirm you would like to delete this recommendation, please click 'Confirm' below.`}
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button type={'button'} color={'primary'} onClick={() => deleteRecommendation(recId)}>
                            {`Confirm`}
                        </Button>
                        <Button
                            type={'button'}
                            color={'secondary'}
                            className={'ml-3'}
                            onClick={() => toggleRemoveModal()}
                        >
                            {`Cancel`}
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={showChangeInst} toggle={toggleShowChangeInst} centered={true}>
                    <ModalHeader>
                        <h4>{`Change Institution`}</h4>
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>{`Institution ID`}</Label>
                            <Input
                                type={'text'}
                                value={changeInstId || ''}
                                disabled={changeNewInst}
                                onChange={(e) => setChangeInstId(e.target.value)}
                            />
                        </FormGroup>
                        {+(recommendation.caahepinstitutionId || 0) > 0 && (
                            <div className={'custom-control custom-checkbox mr-2'}>
                                <Input
                                    type={'checkbox'}
                                    className={'custom-control-input'}
                                    id={'instSetNew'}
                                    checked={changeNewInst}
                                    onChange={(e) => {
                                        setChangeNewInst(e.target.checked);
                                        if (e.target.checked) {
                                            setChangeInstId(undefined);
                                        }
                                    }}
                                />
                                <Label
                                    className={'custom-control-label font-weight-normal'}
                                    for="instSetNew"
                                >{`Set to New Institution`}</Label>
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button type={'button'} color={'primary'} onClick={() => updateInstitution()}>
                            {`Submit`}
                        </Button>
                        <Button
                            type={'button'}
                            color={'secondary'}
                            className={'ml-3'}
                            onClick={() => {
                                toggleShowChangeInst();
                                setChangeInstId(undefined);
                                setChangeNewInst(false);
                            }}
                        >
                            {`Cancel`}
                        </Button>
                    </ModalFooter>
                </Modal>

                {editSatellite && (
                    <Modal isOpen={showSatellite} toggle={toggleShowSatellite} centered>
                        <RecommendationSatelliteForm
                            satellite={editSatellite}
                            onCancel={toggleShowSatellite}
                            onSave={() => {
                                setReloadSatellites(true);
                                toggleShowSatellite();
                            }}
                        />
                    </Modal>
                )}
            </>
        );
    }
};

export default Recommendation;
