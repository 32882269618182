import { AmsPermissionClaimType, authService } from '../../services/Auth';
import { Badge, Button, ButtonGroup, Card, CardBody, Col, Label, Modal, Row } from 'reactstrap';
import { InstitutionPeopleDto, ProfessionLevel, ProgramDto } from '../../common/Types';
import { InstitutionPersonRole, ProgramStatus } from '../../common/Data';
import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import NewProgram from '../../components/NewProgram';
import styled from 'styled-components';
import { professionService } from '../../services/ProfessionAdministration';
import { toast } from 'react-toastify';
styled(Card)`
    margin: 8px;
    display: flex;
    flex-direction: column;
    color: #6c757d;

    h4 {
        white-space: nowrap;
        padding-left: 32px;
    }

    .ribbon-box {
        display: flex;
        flex-direction: column;
    }

    .title-row {
        display: flex;
    }

    .button-row {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        font-size: 24px;
        flex-direction: column;
        flex-grow: 1;
    }

    :hover {
        box-shadow: 0 0 8px 0 rgba(154, 161, 171, 0.3);
    }
`;
type Props = {
    programs: ProgramDto[] | undefined;
    people: InstitutionPeopleDto[] | undefined;
    institutionId: number;
};

const Programs = (props: Props) => {
    const { people, institutionId } = props;
    const [levels, setLevels] = useState<ProfessionLevel[]>();
    const [programs, setPrograms] = useState<ProgramDto[]>(props.programs ?? []);
    const [showNewProgram, setShowNewProgram] = useState<boolean>(false);
    const toggleShowNewProgram = () => setShowNewProgram(!showNewProgram);

    const showProgram = (program: ProgramDto): boolean => {
        return true;
        // if (authService.hasPermission(AmsPermissionClaimType, 'systemadmin')) {
        //     return true;
        // } else {
        //     const programStatusIds = [
        //         ProgramStatus.Initial,
        //         ProgramStatus.Continuing,
        //         ProgramStatus.Probation,
        //         ProgramStatus.Inactive,
        //     ] as number[];

        //     return programStatusIds.filter((p) => p === program.statusId).length > 0;
        // }
    };

    useEffect(() => {
        setPrograms(props.programs?.filter((p) => showProgram(p)).sort((a, b) => a.title.localeCompare(b.title)) ?? []);
    }, [setPrograms, props.programs]);

    useEffect(() => {
        professionService
            .getAllLevels()
            .then((response) => {
                setLevels(response);
            })
            .catch(() => {
                toast.error('Failed to load levels.');
            });
    }, [setLevels]);

    const getProgramDirector = (programId: number): string => {
        const person = people?.find(
            (p) =>
                p.active === true &&
                p.programId === programId &&
                p.people?.active === true &&
                p.roleId === InstitutionPersonRole['Program Director'],
        );

        if (person?.people) {
            return `${person.people.firstName} ${person.people.lastName}`;
        }

        return ``;
    };

    const getDean = (programId: number): string => {
        const person = people?.find(
            (p) =>
                p.active === true &&
                p.programId === programId &&
                p.people?.active === true &&
                p.roleId === InstitutionPersonRole.Dean,
        );

        if (person?.people) {
            return `${person.people.firstName} ${person.people.lastName}`;
        }

        return ``;
    };

    const getAward = (program: ProgramDto): string => {
        const awards = [
            program.degreeCertificate === true ? 'Certificate' : null,
            program.degreeAssociate === true ? 'Associate' : null,
            program.degreeDiploma === true ? 'Diploma' : null,
            program.degreeBaccalaureate === true ? 'Baccalaureate' : null,
            program.degreeMasters === true ? 'Masters' : null,
        ];

        return awards.filter((a) => a).join(', ');
    };

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <CardBody className={'pb-0'}>
                            <h4 className={'card-title d-flex justify-content-between align-items-center'}>
                                <span>{`Programs`}</span>
                                {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                                    <Button onClick={() => toggleShowNewProgram()} type={'button'} color={'primary'}>
                                        <i className={'mdi mdi-plus'} />
                                        <span className={'ml-1'}>{`New Program`}</span>
                                    </Button>
                                )}
                            </h4>
                        </CardBody>
                        <div className={'title-responsive'} style={{ maxHeight: '650px', overflow: 'auto' }}>
                            <table className={'table table-striped'}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>{`Title`}</th>
                                        <th></th>
                                        <th>{`Award`}</th>
                                        <th>{`Program Director`}</th>
                                        <th>{`Dean`}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                {(programs?.length || 0) > 0 && (
                                    <tbody>
                                        {programs.map((p) => (
                                            <tr key={p.programId}>
                                                <td className={'align-middle'}>
                                                    <Label>
                                                        <Badge color={'primary'} className={'p-1'}>
                                                            {p.activeStatus
                                                                ? ProgramStatus[p.activeStatus]
                                                                : 'No Status Set'}
                                                        </Badge>
                                                    </Label>
                                                </td>
                                                <td className={'align-middle'}>
                                                    <NavLink
                                                        to={`/institution/${p.institutionId}/program/${p.programId}`}
                                                    >
                                                        {p.title}
                                                    </NavLink>
                                                </td>
                                                <td className={`align-midde`}>
                                                    {p.professionLevelId &&
                                                        levels &&
                                                        p.professionLevelId > 0 &&
                                                        // @ts-ignore
                                                        levels.find((l) => l.id === +p.professionLevelId)?.title}
                                                </td>
                                                <td className={`align-midde`}>{getAward(p)}</td>
                                                <td className={'align-middle'}>{getProgramDirector(p.programId)}</td>
                                                <td className={'align-middle'}>{getDean(p.programId)}</td>
                                                <td className={'align-middle'}>
                                                    <ButtonGroup>
                                                        <NavLink
                                                            to={`/institution/${p.institutionId}/program/${p.programId}`}
                                                            className={'btn btn-info'}
                                                        >
                                                            {`View`}
                                                        </NavLink>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                                {(programs?.length || 0) === 0 && (
                                    <tbody>
                                        <tr>
                                            <td colSpan={5}>{`No Programs`}</td>
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </Card>
                </Col>
            </Row>
            {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                <Modal size={'lg'} isOpen={showNewProgram} toggle={toggleShowNewProgram}>
                    <NewProgram
                        onCanceled={toggleShowNewProgram}
                        onSaved={(newProgram) => setPrograms([...programs, { ...newProgram }])}
                        institutionId={institutionId}
                    />
                </Modal>
            )}
        </>
    );
};

export default Programs;
