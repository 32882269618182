import React, { useEffect, useState } from 'react';
import { Button } from '../../components';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { invoiceService } from '../../services/InvoiceService';
import { PaymentType } from '../../common/Data';
import { FormGroup, Input, Label } from 'reactstrap';

const currencyFormatter = new Intl.NumberFormat(`en-US`, {
    style: `currency`,
    currency: `USD`,
});

const PaymentForm = ({ invoiceId, amount, professionIds, paymentIntentId, recipientEmail, onEmailChange }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [emailAddress, setEmailAddress] = useState<string>(recipientEmail);

    const formatMoneyOrZero = (amt: number | undefined | null): string => {
        return currencyFormatter.format(amt || 0);
    };

    useEffect(() => {
        if (!!emailAddress) {
            onEmailChange(emailAddress);
        }
    }, [emailAddress]);

    //const convenienceFee = amount - amount / 1.029;
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!!!stripe || !!!elements) {
            return;
        }

        setIsProcessing(true);

        const result = await stripe.confirmPayment({
            elements,
            redirect: 'if_required',

            confirmParams: {
                /* eslint-disable @typescript-eslint/camelcase */
                receipt_email: emailAddress,
            },
        });

        if (result.error) {
            toast.error(`There was an error processing your payment: ${result.error.message}`);
        }
        if (result?.paymentIntent?.status == 'succeeded') {
            try {
                const paymentResult = await invoiceService.saveInvoicePayment({
                    invoiceId,
                    isDeleted: false,
                    isRefund: false,
                    isConfirmed: true,
                    paymentAmount: parseFloat(amount.toFixed(2)),
                    //convenienceFee: parseFloat(convenienceFee.toFixed(2)),
                    convenienceFee: 0,
                    paymentType: PaymentType['Credit Card'],
                    professionIds,
                    transactionId: paymentIntentId,
                    address: undefined,
                    checkInformation: undefined,
                    city: undefined,
                    country: undefined,
                    createDate: undefined,
                    email: undefined,
                    firstName: undefined,
                    id: 0,
                    lastName: undefined,
                    paidBy: undefined,
                    paymentDate: new Date(),
                    referenceNumber: undefined,
                    rowVersion: undefined,
                    state: undefined,
                    zipCode: undefined,
                    receiptUrl: undefined,
                });
                window.location.href = `${window.location.origin}/payments/thank-you?receipt_url="${paymentResult.receiptUrl}"`;
            } catch (ex) {
                toast.warning(
                    `There was an error recording your payment, please contact CAAHEP to ensure your payment is properly recorded providing the following Transaction Number: ${paymentIntentId}`,
                );
            }
        } else {
            toast.error(`There was an error processing your payment. Please try again.`);
        }

        setIsProcessing(false);
    };

    return (
        <>
            <h4 className={`mb-3`}>Payment Form</h4>
            <div className={`mb-3`}>
                {amount > 0 && <h5>Payment Amount: {formatMoneyOrZero(amount)}</h5>}
                {amount === 0 && <h5>Please select at least one line item above to pay</h5>}
            </div>
            <form onSubmit={handleSubmit}>
                <PaymentElement />
                <FormGroup className={`mt-1`}>
                    <Label>{`Email Address`}</Label>
                    <Input type={'email'} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                </FormGroup>
                {/*<div className={`mt-3`}>*/}
                {/*    {`By selecting ‘credit card', as the form of payment you agree to pay a */}
                {/*     convenience fee equal to 2.9% (${formatMoneyOrZero(*/}
                {/*         convenienceFee,*/}
                {/*     )}), which will be reflected on your receipt.  */}
                {/*     Payments made by check or ACH do not include a convenience fee.`}*/}
                {/*</div>*/}
                <div className={`mt-3`}>
                    {`To pay by ACH `}
                    <a href={`${window.location.origin}/payments/ach-info`}>click here.</a>
                </div>
                <div className={`mt-3`}>
                    {`By entering your credit card information and clicking the Submit Payment
                     button, you are authorizing CAAHEP to process your credit card for a 
                     one-time payment in the amount of ${formatMoneyOrZero(amount)}.`}
                </div>
                <Button
                    type={`submit`}
                    className={`mt-3`}
                    disabled={amount === 0 || !!!stripe || isProcessing || !!!emailAddress}
                >
                    Submit Payment
                </Button>
            </form>
        </>
    );
};

export default PaymentForm;
