import * as yup from 'yup';

import { AuspiceDto, Institution, InstitutionTypeDto } from '../common/Types';
import { Card, CardBody, CardFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import Button from './Button';
import { Redirect } from 'react-router-dom';
import { institutionService } from '../services/InstitutionService';
import { toast } from 'react-toastify';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { Countries, States } from '../common/Data';
import institution from '../pages/institutions/Institution';

interface NewInstitutionProps {
    onSaved: (newInstitution: Institution) => void;
    onCanceled: () => void;
}

const NewInstitution = (props: NewInstitutionProps) => {
    const { onSaved, onCanceled } = props;

    const [navigateToId, setNavigateToId] = useState<number>();
    const [institutionTypes, setInstitutionTypes] = useState<InstitutionTypeDto[]>();
    const [auspices, setAuspices] = useState<AuspiceDto[]>();
    const [isInternational, setIsInternational] = useState<boolean>(false);

    useEffect(() => {
        const loadInstitutionTypes = () => {
            institutionService
                .getTypes()
                .then((results) => {
                    setInstitutionTypes([
                        ...(results.filter((r) => r.isActive === true).sort((a, b) => a.title.localeCompare(b.title)) ||
                            []),
                    ]);
                })
                .catch(() => {
                    toast.error('Error loading institution types');
                });
        };

        const loadAuspices = () => {
            institutionService
                .getAuspice()
                .then((results) => {
                    setAuspices([
                        ...(results.filter((r) => r.isActive === true).sort((a, b) => a.title.localeCompare(b.title)) ||
                            []),
                    ]);
                })
                .catch(() => {
                    toast.error('Error loading auspices');
                });
        };

        loadInstitutionTypes();
        loadAuspices();
    }, [setInstitutionTypes, setAuspices]);

    const institutionSchema: yup.ObjectSchema<Institution> = yup
        .object({
            institutionId: yup.number().required().default(0),
            institutionTypeId: yup
                .number()
                .required('Institution Type is required')
                .min(1, 'Institution Type is required'),
            auspiceId: yup.number().required('Auspice is required').min(1, 'Auspice is required'),
            title: yup.string().required(),
            city: yup.string().notRequired().nullable().default(null),
            state: yup.string().notRequired().nullable().default(null),
            address: yup.string().notRequired().nullable().default(null),
            address2: yup.string().notRequired().nullable().default(null),
            address3: yup.string().notRequired().nullable().default(null),
            address4: yup.string().notRequired().nullable().default(null),
            zip: yup.string().notRequired().nullable().default(null),
            country: yup.string().notRequired().nullable().default(null),
            phone: yup.string().notRequired().nullable().default(null),
            extension: yup.string().notRequired().nullable().default(null),
            fax: yup.string().notRequired().nullable().default(null),
            webSite: yup.string().notRequired().nullable().default(null),
            ceoId: yup.number().notRequired().nullable().default(null),
            createdBy: yup.string().notRequired().nullable().default(null),
            dateCreated: yup.date().notRequired().nullable().default(null),
            lastEdited: yup.date().notRequired().nullable().default(null),
            editedBy: yup.string().notRequired().nullable().default(null),
            active: yup.boolean().required().default(true),
            preserveBillingInformation: yup.boolean().required().default(false),
            synchronizeCeoInstitution: yup.boolean().required().default(false),
            institutionType: yup.string().notRequired(),
        })
        .defined();

    const { handleSubmit, register, errors, watch, setValue, control } = useForm({
        defaultValues: {} as Institution,
        validationSchema: institutionSchema,
    });

    const country = watch('country');

    useEffect(() => {
        if (country && country !== 'United States of America') {
            setIsInternational(true);
        } else {
            setIsInternational(false);
        }
    }, [country, setValue]);

    const submitForm = (values: Institution) => {
        const toastId = toast.info('Creating institution...');

        institutionService
            .saveInstitutions(new Institution(values))
            .then((result) => {
                toast.update(toastId, {
                    render: 'Institution created successfully',
                    type: 'success',
                });

                setNavigateToId(result.institutionId);
            })
            .catch(() => {
                toast.update(toastId, {
                    render: 'Error creating institution',
                    type: 'error',
                });
            });
    };

    if (navigateToId && navigateToId > 0) {
        return <Redirect to={`/institution/${navigateToId}`} />;
    } else {
        return (
            <>
                <Form onSubmit={handleSubmit(submitForm)}>
                    <Card className={'mb-0'}>
                        <CardBody>
                            <h4>{`New Institution`}</h4>

                            <FormGroup>
                                <Label>{`Name`}</Label>
                                <Input type={'text'} name={'title'} innerRef={register()} />
                                {errors?.title?.message && (
                                    <span className={'text-danger'}>{errors.title.message}</span>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>{`Institution Type`}</Label>
                                <Input type={'select'} name={'institutionTypeId'} innerRef={register()}>
                                    <option value={-1}>{`Select an Institution Type`}</option>
                                    {institutionTypes?.map((t) => (
                                        <option key={t.institutionTypeId} value={t.institutionTypeId}>
                                            {t.title}
                                        </option>
                                    ))}
                                </Input>
                                {errors?.institutionTypeId?.message && (
                                    <span className={'text-danger'}>{errors.institutionTypeId.message}</span>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>{`Control`}</Label>
                                <Input type={'select'} name={'auspiceId'} innerRef={register()}>
                                    <option value={-1}>{`Select an Auspice`}</option>
                                    {auspices?.map((a) => (
                                        <option key={a.auspiceID} value={a.auspiceID}>
                                            {a.title}
                                        </option>
                                    ))}
                                </Input>
                                {errors?.auspiceId?.message && (
                                    <span className={'text-danger'}>{errors.auspiceId.message}</span>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>{`Phone`}</Label>
                                <Input type={'text'} name={'phone'} innerRef={register()} />
                                {errors?.phone?.message && (
                                    <span className={'text-danger'}>{errors.phone.message}</span>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>{`Extension`}</Label>
                                <Input type={'text'} name={'extension'} innerRef={register()} />
                                {errors?.extension?.message && (
                                    <span className={'text-danger'}>{errors.extension.message}</span>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>{`Fax`}</Label>
                                <Input type={'text'} name={'fax'} innerRef={register()} />
                                {errors?.fax?.message && <span className={'text-danger'}>{errors.fax.message}</span>}
                            </FormGroup>

                            <FormGroup>
                                <Label>{`Website`}</Label>
                                <Input type={'text'} name={'webSite'} innerRef={register()} />
                                {errors?.webSite?.message && (
                                    <span className={'text-danger'}>{errors.webSite.message}</span>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>{`Address`}</Label>
                                <Input type={'text'} name={'address'} innerRef={register()} />
                                {errors?.address?.message && (
                                    <span className={'text-danger'}>{errors.address.message}</span>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>{`Address 2`}</Label>
                                <Input type={'text'} name={'address2'} innerRef={register()} />
                                {errors?.address2?.message && (
                                    <span className={'text-danger'}>{errors.address2.message}</span>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>{`Address 3`}</Label>
                                <Input type={'text'} name={'address3'} innerRef={register()} />
                                {errors?.address3?.message && (
                                    <span className={'text-danger'}>{errors.address3.message}</span>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>{`Address 4`}</Label>
                                <Input type={'text'} name={'address4'} innerRef={register()} />
                                {errors?.address4?.message && (
                                    <span className={'text-danger'}>{errors.address4.message}</span>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>{`City`}</Label>
                                <Input type={'text'} name={'city'} innerRef={register()} />
                                {errors?.city?.message && <span className={'text-danger'}>{errors.city.message}</span>}
                            </FormGroup>

                            <FormGroup>
                                <Label>{isInternational ? `State/Province` : `State`}</Label>
                                {isInternational && (
                                    <div>
                                        <Input type={'text'} name={'state'} innerRef={register()} />
                                    </div>
                                )}
                                {!isInternational && (
                                    <div>
                                        <Controller as={Input} type={'select'} control={control} name={'state'}>
                                            <option value={undefined}></option>
                                            {States &&
                                                States.map((s) => (
                                                    <option key={s.abbreviation} value={s.abbreviation}>
                                                        {s.abbreviation}
                                                    </option>
                                                ))}
                                        </Controller>
                                    </div>
                                )}
                                {errors?.state?.message && (
                                    <span className={'text-danger'}>{errors.state.message}</span>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>{isInternational ? `Postal Code` : `Zip`}</Label>
                                <Input type={'text'} name={'zip'} innerRef={register()} />
                                {errors?.zip?.message && <span className={'text-danger'}>{errors.zip.message}</span>}
                            </FormGroup>

                            <FormGroup>
                                <Label>{`Country`}</Label>
                                <Controller as={Input} type={'select'} control={control} name={'country'}>
                                    <option value={undefined}></option>
                                    {Countries.map((c) => (
                                        <option key={c} value={c}>
                                            {c}
                                        </option>
                                    ))}
                                </Controller>{' '}
                                {errors?.country?.message && (
                                    <span className={'text-danger'}>{errors.country.message}</span>
                                )}
                            </FormGroup>
                        </CardBody>
                        <CardFooter>
                            <Button color={'primary'} type={'submit'}>
                                {`Create Institution`}
                            </Button>
                            <Button color={'secondary'} type={'button'} className={'ml-2'} onClick={() => onCanceled()}>
                                {`Cancel`}
                            </Button>
                        </CardFooter>
                    </Card>
                </Form>
            </>
        );
    }
};

export default NewInstitution;
