import { Card, CardBody, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
// @ts-ignore
import React, { useEffect, useState } from 'react';

import { Button } from '../../../components';
import { ProfessionLevel } from '../../../common/Types';
import Skeleton from 'react-loading-skeleton';
import { professionService } from '../../../services/ProfessionAdministration';
import { toast } from 'react-toastify';

interface LevelsCardProps {
    professionId: number;
}

const LevelsCard = (props: LevelsCardProps) => {
    const { professionId } = props;

    const [levels, setLevels] = useState<ProfessionLevel[]>();
    const [reloadLevels, setReloadLevels] = useState<boolean>(true);

    const [levelEdit, setLevelEdit] = useState<ProfessionLevel>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const toggleModal = () => setShowModal(!showModal);

    useEffect(() => {
        const getLevels = () => {
            if (professionId && reloadLevels) {
                setReloadLevels(false);
                professionService
                    .getLevelsByProfessionId(professionId)
                    .then((result) => {
                        setLevels([...result]);
                    })
                    .catch(() => {
                        toast.error('Error loading levels');
                    });
            }
        };

        getLevels();
    }, [professionId, setLevels, reloadLevels]);

    const startNewLevel = () => {
        setLevelEdit({
            id: 0,
            professionId: professionId,
            title: '',
        });
        toggleModal();
    };

    const startEditLevel = (level: ProfessionLevel) => {
        setLevelEdit({
            ...level,
        });
        toggleModal();
    };

    const saveLevel = () => {
        if (levelEdit && levelEdit.title) {
            const toastId = toast.info('Saving level...');

            professionService
                .saveLevel(levelEdit)
                .then(() => {
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Level saved successfully',
                    });

                    setReloadLevels(true);
                    toggleModal();
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error saving level',
                    });
                });
        }

        if (levelEdit && !levelEdit.title) {
            toast.error('Level Title is required');
        }
    };

    return (
        <>
            <Card>
                <CardBody className={'d-flex flex-column'}>
                    <h4 className={'card-title d-flex align-items-center justify-content-between'}>
                        <span>{`Levels`}</span>
                        <div>
                            <Button
                                type={'button'}
                                color={'primary'}
                                className={'btn-icon'}
                                onClick={() => startNewLevel()}
                            >
                                <i className={`mdi mdi-plus`} />
                            </Button>
                        </div>
                    </h4>
                    {!levels && <Skeleton count={5} />}
                    {levels && levels.length <= 0 && <Label>{`No Levels`}</Label>}
                    {levels &&
                        levels.length > 0 &&
                        levels.map((t) => (
                            <div key={t.id} className={'d-flex justify-content-between align-items-center mb-2'}>
                                <span>{t.title}</span>
                                <div>
                                    <Button
                                        type={'button'}
                                        color={'info'}
                                        className={'btn-icon'}
                                        onClick={() => startEditLevel(t)}
                                    >
                                        <i className={'mdi mdi-pencil'} />
                                    </Button>
                                </div>
                            </div>
                        ))}
                </CardBody>
            </Card>

            {levelEdit && (
                <Modal isOpen={showModal} toggle={toggleModal} centered={true}>
                    <ModalHeader>{levelEdit.id > 0 ? `Edit Level` : `New Level`}</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>{`Level Title`}</Label>
                            <Input
                                type={'text'}
                                value={levelEdit.title}
                                onChange={(e) => setLevelEdit({ ...levelEdit, title: e.target.value })}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={'primary'}
                            className={'mr-2'}
                            type={'button'}
                            onClick={() => saveLevel()}
                        >{`Submit`}</Button>
                        <Button color={'link'} onClick={() => toggleModal()} type={'button'}>{`Cancel`}</Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

export default LevelsCard;
