import {
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
} from 'reactstrap';
import { DegreeTypes } from '../../common/Data';
import React, { useEffect, useState } from 'react';

import { Button } from '../../components';
import MultiSelect from 'react-multi-select-component';
import { ProgramStatus, States } from '../../common/Data';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import {
    AccreditedProgramDto,
    AuspiceDto,
    Institution,
    InstitutionTypeDto,
    ProfessionDto,
    ProfessionLevel,
} from '../../common/Types';
import { professionService } from '../../services/ProfessionAdministration';
import { institutionService } from '../../services/InstitutionService';
import { programService } from '../../services/ProgramService';
import { reportService } from '../../services/ReportService';
import moment from 'moment';
import { PagingDto } from '../../types';

const StyledMultiSelect = styled(MultiSelect)`
    width: 300px;

    & .dropdown-container {
        width: 100%;
        max-width: 100%;
    }
    & .dropdown-content {
        z-index: 3;
    }
    & .select-item {
        display: block;
    }
`;
const AccreditedProgramsReport = () => {
    const [total, setTotal] = useState(0);
    const [professionId, setProfessionId] = useState<number>();
    const [selectedStatus, setSelectedStatus] = useState<[]>();
    const [effectiveDate, setEffectiveDate] = useState<Date>();
    const [asOfEffectiveDate, setAsOfEffectiveDate] = useState<Date>();
    const [initialAccreditationDate, setInitialAccreditationDate] = useState<Date>();
    const [institutionId, setInstitutionId] = useState<number>();
    const [institutionTypeId, setInstitutionTypeId] = useState<number>();
    const [programName, setProgramName] = useState<string>();
    const [selectedDegreeType, setSelectedDegreeType] = useState<[]>();
    const [institutions, setInstitutions] = useState<Institution[]>([]);
    const [auspices, setAuspices] = useState<AuspiceDto[]>();
    const [institutionTypes, setInstitutionTypes] = useState<InstitutionTypeDto[]>();
    const [selectedAuspices, setSelectedAuspices] = useState<[]>();
    const [city, setCity] = useState<string>();
    const [selectedStates, setSelectedStates] = useState<[]>();
    const [distance, setDistance] = useState<boolean>(false);
    const [programNames, setProgramNames] = useState<string[]>([]);
    const [professions, setProfessions] = useState<ProfessionDto[]>();
    const [accreditedPrograms, setAccreditedPrograms] = useState<AccreditedProgramDto[]>();
    const [levels, setLevels] = useState<ProfessionLevel[]>();
    const [filteredLevels, setFilteredLevels] = useState<ProfessionLevel[]>();
    const [professionLevelId, setProfessionLevelId] = useState<number>();
    const [paging, setPaging] = useState<PagingDto>({
        pageNumber: 1,
        maxPageSize: null,
        pageSize: 10,
    });

    useEffect(() => {
        const loadProfessions = () => {
            professionService
                .getProfessions()
                .then((results) => {
                    setProfessions([
                        ...results
                            .filter((p) => p.professionId == 32 || !p.isDeleted)
                            .sort((a, b) => a.title.localeCompare(b.title)),
                    ]);
                })
                .catch(() => {
                    toast.error('Error loading professions');
                });
        };
        loadProfessions();
    }, [setProfessions]);

    useEffect(() => {
        professionService
            .getAllLevels()
            .then((response) => {
                setLevels(response);
                setFilteredLevels(response);
            })
            .catch(() => {
                toast.error('Failed to load levels.');
            });
    }, [setLevels, setFilteredLevels]);

    useEffect(() => {
        const loadInstitutions = () => {
            institutionService
                .getInstitutions()
                .then((response) => {
                    setInstitutions(response);
                })
                .catch(() => {
                    toast.error('Error loading institutions');
                });
        };
        const loadAuspices = () => {
            institutionService
                .getAuspice()
                .then((results) => {
                    setAuspices([
                        ...(results.filter((r) => r.isActive === true).sort((a, b) => a.title.localeCompare(b.title)) ||
                            []),
                    ]);
                })
                .catch(() => {
                    toast.error('Error loading auspices');
                });
        };
        const loadInstitutionTypes = () => {
            institutionService
                .getTypes()
                .then((results) => {
                    setInstitutionTypes([
                        ...(results.filter((r) => r.isActive === true).sort((a, b) => a.title.localeCompare(b.title)) ||
                            []),
                    ]);
                })
                .catch(() => {
                    toast.error('Error loading institution types');
                });
        };
        loadInstitutionTypes();
        loadInstitutions();
        loadAuspices();
    }, [setInstitutions, setAuspices, setInstitutionTypes]);

    useEffect(() => {
        programService
            .getProgramNames()
            .then((response) => {
                setProgramNames(response);
            })
            .catch(() => toast.error('Failed to get program names'));
    }, [setProgramNames]);

    useEffect(() => {
        const toastId = toast.info('Searching...', {
            autoClose: false,
        });
        const getData = () => {
            reportService
                .getAccreditedPrograms(
                    paging,
                    professionId,
                    selectedStatus?.map((s) => s['value']),
                    effectiveDate ? moment(effectiveDate).format() : effectiveDate,
                    asOfEffectiveDate ? moment(asOfEffectiveDate).format() : asOfEffectiveDate,
                    initialAccreditationDate ? moment(initialAccreditationDate).format() : initialAccreditationDate,
                    institutionId,
                    institutionTypeId,
                    programName,
                    selectedDegreeType?.map((d) => d['value']),
                    selectedAuspices?.map((a) => a['value']),
                    city,
                    selectedStates?.map((s) => s['value']),
                    distance,
                    professionLevelId,
                )
                .then((response) => {
                    setAccreditedPrograms(response.records);
                    setTotal(response.total);
                    toast.update(toastId, {
                        type: 'success',
                        autoClose: 500,
                    });
                })
                .catch(() => toast.error('Failed to get accredited programs'));
        };

        getData();
    }, [setAccreditedPrograms, paging]);

    const { register, handleSubmit, reset } = useForm();

    const clearSearchForm = () => {
        setProfessionId(0);
        setProfessionLevelId(0);
        setSelectedStatus([]);
        setEffectiveDate(undefined);
        setAsOfEffectiveDate(undefined);
        setInitialAccreditationDate(undefined);
        setInstitutionId(0);
        setInstitutionTypeId(0);
        setProgramName('');
        setSelectedDegreeType([]);
        setSelectedAuspices([]);
        setCity('');
        setSelectedStates([]);
        setDistance(false);
        setFilteredLevels(levels);
        setPaging({
            pageNumber: 1,
            maxPageSize: null,
            pageSize: 10,
        });
        reset({
            professionId: 0,
            professionLevelId: 0,
            selectedStatus: null,
            effectiveDate: null,
            asOfEffectiveDate: null,
            initialAccreditationDate: 0,
            institutionId: null,
            institutionTypeId: null,
            programName: null,
            selectedDegreeType: 0,
            selectedAuspices: null,
            city: null,
            selectedStates: null,
            distance: null,
            paging: {
                pageNumber: 1,
                maxPageSize: null,
                pageSize: 10,
            },
        });
    };

    const filterLevels = (professionId: number) => {
        if (professionId > 0) {
            setFilteredLevels([...(levels?.filter((l) => l.professionId === professionId) || [])]);
        } else {
            setFilteredLevels(levels);
        }
    };

    const onSubmit = () => {
        setPaging({
            ...paging,
        });
    };

    const exportReport = () => {
        const toastId = toast.info('Generating report, this may take a minute...', {
            autoClose: false,
        });
        reportService
            .exportAccreditedProgramReport(
                professionId,
                selectedStatus?.map((s) => s['value']),
                effectiveDate ? moment(effectiveDate).format() : effectiveDate,
                asOfEffectiveDate ? moment(asOfEffectiveDate).format() : asOfEffectiveDate,
                initialAccreditationDate ? moment(initialAccreditationDate).format() : initialAccreditationDate,
                institutionId,
                institutionTypeId,
                programName,
                selectedDegreeType?.map((d) => d['value']),
                selectedAuspices?.map((a) => a['value']),
                city,
                selectedStates?.map((s) => s['value']),
                distance,
                professionLevelId,
            )
            .then((report) => {
                if (report) {
                    const url = window.URL.createObjectURL(report);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `AccreditedProgramReport.xlsx`;
                    a.click();
                    toast.update(toastId, {
                        render: 'Report generated successfully.',
                        type: 'success',
                        autoClose: 2500,
                    });
                }
            })
            .catch(() => {
                toast.error(
                    `Unable to generate report',
                    )}`,
                );
            });
    };

    return (
        <>
            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <h3>Accredited Programs Report</h3>
                            </div>
                            <Form onSubmit={handleSubmit(onSubmit)} inline>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="professionId" className="mr-sm-2">
                                        Profession
                                    </Label>
                                    <Input
                                        type="select"
                                        name={'professionId'}
                                        innerRef={register()}
                                        style={{ width: '200px' }}
                                        onChange={(e) => {
                                            setProfessionId(
                                                professions?.find((p) => p.professionId === +e.target.value)
                                                    ?.professionId,
                                            );
                                            filterLevels(+e.target.value);
                                        }}
                                    >
                                        <option value={''}>Select...</option>
                                        {professions?.map((p) => (
                                            <option key={p.professionId} value={p.professionId}>
                                                {p.title}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="professionLevelId" className="mr-sm-2">
                                        Profession Level
                                    </Label>
                                    <Input
                                        type="select"
                                        name={'professionLevelId'}
                                        innerRef={register()}
                                        style={{ width: '200px' }}
                                        onChange={(e) =>
                                            setProfessionLevelId(levels?.find((p) => p.id === +e.target.value)?.id)
                                        }
                                    >
                                        <option value={''}>Select...</option>
                                        {filteredLevels?.map((p) => (
                                            <option key={p.id} value={p.id}>
                                                {p.title}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="status" className="mr-sm-2">
                                        Status
                                    </Label>
                                    <StyledMultiSelect
                                        options={Object.entries(ProgramStatus)
                                            .filter((p) => !isNaN(p[0] as any) && Number(p[0]) <= 13)
                                            .map((p) => {
                                                return {
                                                    label: p[1],
                                                    value: p[0],
                                                };
                                            })}
                                        disableSearch={true}
                                        hasSelectAll={false}
                                        value={selectedStatus}
                                        onChange={setSelectedStatus}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <div className={'mb-3 custom-control custom-checkbox'}>
                                        <Input
                                            type="checkbox"
                                            id={'distance'}
                                            name={'distance'}
                                            defaultChecked={distance}
                                            innerRef={register()}
                                            onChange={(e) => setDistance(e.target.checked)}
                                            className={'custom-control-input'}
                                        />
                                        <Label for={'distance'} className={'mr-sm-2 custom-control-label'}>
                                            Distance
                                        </Label>
                                    </div>
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="effectiveDate" className="mr-sm-2">
                                        Effective Date (Exact):
                                    </Label>
                                    <Input
                                        type="date"
                                        innerRef={register()}
                                        name="effectiveDate"
                                        onChange={(e) => setEffectiveDate(new Date(e.target.value))}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="asOfEffectiveDate" className="mr-sm-2">
                                        Effective Date (As Of):
                                    </Label>
                                    <Input
                                        type="date"
                                        innerRef={register()}
                                        name="asOfEffectiveDate"
                                        onChange={(e) => setAsOfEffectiveDate(new Date(e.target.value))}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="initialAccreditationDate" className="mr-sm-2">
                                        Initial Accreditation Date:
                                    </Label>
                                    <Input
                                        type="date"
                                        innerRef={register()}
                                        name="initialAccreditationDate"
                                        onChange={(e) => setInitialAccreditationDate(new Date(e.target.value))}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="institutionId" className="mr-sm-2">
                                        Institution
                                    </Label>
                                    <Input
                                        type="select"
                                        innerRef={register()}
                                        name="institutionId"
                                        style={{ width: '200px' }}
                                        onChange={(e) =>
                                            setInstitutionId(
                                                institutions?.find((i) => i.institutionId === +e.target.value)
                                                    ?.institutionId,
                                            )
                                        }
                                    >
                                        <option value={''}>Select...</option>
                                        {institutions?.map((i) => (
                                            <option key={i.institutionId} value={i.institutionId}>
                                                {i.title}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="institutionTypeId" className="mr-sm-2">
                                        Institution Type:
                                    </Label>
                                    <Input
                                        type="select"
                                        innerRef={register()}
                                        name="institutionTypeId"
                                        style={{ width: '200px' }}
                                        onChange={(e) =>
                                            setInstitutionTypeId(
                                                institutionTypes?.find((t) => t.institutionTypeId === +e.target.value)
                                                    ?.institutionTypeId,
                                            )
                                        }
                                    >
                                        <option value={''}>Select...</option>
                                        {institutionTypes?.map((t) => (
                                            <option key={t.institutionTypeId} value={t.institutionTypeId}>
                                                {t.title}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="programName" className="mr-sm-2">
                                        Profession:
                                    </Label>
                                    <Input
                                        type="select"
                                        innerRef={register()}
                                        name="programName"
                                        style={{ width: '200px' }}
                                        onChange={(e) => setProgramName(e.target.value)}
                                    >
                                        <option value={''}>Select...</option>
                                        {programNames?.map((p) => (
                                            <option key={p} value={p}>
                                                {p}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="degreeTypes" className="mr-sm-2">
                                        Degree Types
                                    </Label>
                                    <StyledMultiSelect
                                        options={Object.entries(DegreeTypes)
                                            .filter((d) => !isNaN(d[0] as any))
                                            .map((d) => {
                                                return {
                                                    label: d[1],
                                                    value: d[0],
                                                };
                                            })}
                                        disableSearch={true}
                                        hasSelectAll={false}
                                        value={selectedDegreeType}
                                        onChange={setSelectedDegreeType}
                                    />
                                </FormGroup>
                                {auspices && auspices?.length > 0 && (
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                        <Label for="auspice" className="mr-sm-2">
                                            Auspice
                                        </Label>
                                        <StyledMultiSelect
                                            options={auspices?.map((a) => {
                                                return {
                                                    label: a.title,
                                                    value: a.auspiceID,
                                                };
                                            })}
                                            disableSearch={true}
                                            hasSelectAll={false}
                                            value={selectedAuspices}
                                            onChange={setSelectedAuspices}
                                        />
                                    </FormGroup>
                                )}
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="city" className="mr-sm-2">
                                        City
                                    </Label>
                                    <Input
                                        type="text"
                                        innerRef={register()}
                                        name="city"
                                        placeholder="City"
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                    <Label for="state" className="mr-sm-2">
                                        State
                                    </Label>
                                    <StyledMultiSelect
                                        options={States?.map((s) => {
                                            return {
                                                label: s.name,
                                                value: s.abbreviation,
                                            };
                                        })}
                                        disableSearch={true}
                                        hasSelectAll={false}
                                        value={selectedStates}
                                        onChange={setSelectedStates}
                                    />
                                </FormGroup>
                                <div className="mb-2 mr-sm-2 mb-sm-2">
                                    <Button type="submit">Search</Button>
                                </div>
                                <div className=" mr-sm-2">
                                    <Button color="link" onClick={() => clearSearchForm()}>
                                        Clear
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col style={{ display: 'flex' }}>
                            <div className="mr-2">
                                <Button color="info" onClick={() => exportReport()}>
                                    Export Report
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-3">
                        <h4>Accredited Programs</h4>
                    </div>
                </CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th>Profession</th>
                            <th></th>
                            <th>Program</th>
                            <th></th>
                            <th>Degrees</th>
                            <th>Institution</th>
                            <th></th>
                            <th>Program City</th>
                            <th>Program State</th>
                            <th>Distance</th>
                            <th>Accreditation Status</th>
                            <th>Status Effective Date</th>
                            <th>Next Full Evaluation</th>
                            <th>Progress Report Due Date</th>
                            <th>Outcomes Url</th>
                        </tr>
                    </thead>
                    <tbody>
                        {accreditedPrograms ? (
                            accreditedPrograms.map((program, i) => (
                                <tr key={program.programId ?? i}>
                                    <td>{program.professionTitle}</td>
                                    <td>
                                        {program.professionLevelTitle
                                            ? program.professionLevelTitle
                                            : program.concentrationTitle}
                                    </td>
                                    <td>{program.programId}</td>
                                    <td>{program.programName}</td>
                                    <td>{program.degrees}</td>
                                    <td>{program.institutionId}</td>
                                    <td>{program.institutionName}</td>
                                    <td>{program.city}</td>
                                    <td>{program.state}</td>
                                    <td>{program.distance ? 'distance' : ''}</td>
                                    <td>{program.accreditationStatusTitle}</td>
                                    <td>
                                        {program.statusEffectiveDate &&
                                            new Date(program.statusEffectiveDate).toDateString()}
                                    </td>
                                    <td>{program.evaluationDate && new Date(program.evaluationDate).toDateString()}</td>
                                    <td>{program.prDue && new Date(program.prDue).toDateString()}</td>
                                    <td>{program.outcomesUrl}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={7}>No Results</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <CardBody>
                    <h4>Total: {total}</h4>
                    <Pagination size="sm" aria-label="Accredited Programs Navigation">
                        <PaginationItem disabled={paging.pageNumber === 1}>
                            <PaginationLink
                                previous
                                href=""
                                onClick={() => setPaging({ ...paging, pageNumber: paging.pageNumber - 1 })}
                            />
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink
                                next
                                href=""
                                onClick={() => setPaging({ ...paging, pageNumber: paging.pageNumber + 1 })}
                            />
                        </PaginationItem>
                    </Pagination>
                </CardBody>
            </Card>
        </>
    );
};

export default AccreditedProgramsReport;
