import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { Button } from '../../components';
import { toast } from 'react-toastify';
import { InactiveStatusReportDto } from '../../common/Types';
import { reportService } from '../../services/ReportService';
import moment from 'moment';

const InactiveStatusReport = () => {
    const [statusReport, setStatusReport] = useState<InactiveStatusReportDto>();
    useEffect(() => {
        const loadReport = () => {
            const toastId = toast.info('Searching...', {
                autoClose: false,
            });
            reportService
                .getInactiveStatusReport()
                .then((response) => {
                    setStatusReport(response);
                    toast.update(toastId, {
                        type: 'success',
                        autoClose: 500,
                    });
                })
                .catch(() => toast.error('Failed to generate report'));
        };
        loadReport();
    }, [setStatusReport]);

    const exportReport = () => {
        const toastId = toast.info('Generating report, this may take a minute...', {
            autoClose: false,
        });
        reportService
            .exportInactiveStatusReport()
            .then((report) => {
                if (report) {
                    const url = window.URL.createObjectURL(report);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `InactiveStatusReport.xlsx`;
                    a.click();
                    toast.update(toastId, {
                        render: 'Report generated successfully.',
                        type: 'success',
                        autoClose: 2500,
                    });
                }
            })
            .catch(() => {
                toast.error(`Unable to generate report`);
            });
    };

    return (
        <>
            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <h3>Inactive Status Report</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col style={{ display: 'flex' }}>
                            <div className="mr-2">
                                <Button color="info" onClick={() => exportReport()}>
                                    Export Report
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-3">
                        <h4>Programs with Inactive Status</h4>
                    </div>
                </CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th>Profession</th>
                            <th></th>
                            <th>Program</th>
                            <th></th>
                            <th>Institution Name</th>
                            <th>Program City</th>
                            <th>Program State</th>
                            <th>Inactive Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {statusReport?.inactiveStatusDtos ? (
                            statusReport.inactiveStatusDtos.map((record) => (
                                <tr key={record.programId}>
                                    <td>{record.professionTitle}</td>
                                    <td>{record.professionLevelTitle}</td>
                                    <td>{record.programId}</td>
                                    <td>{record.programName}</td>
                                    <td>{record.institutionTitle}</td>
                                    <td>{record.city}</td>
                                    <td>{record.state}</td>
                                    <td>{moment(record.inactiveDate).format('MM/DD/YYYY')}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={7}>No Results</td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>Total Records</th>
                            <th>{statusReport?.totalCount}</th>
                        </tr>
                    </tfoot>
                </Table>
            </Card>
        </>
    );
};

export default InactiveStatusReport;
