import { AmsPermissionClaimType, authService } from '../../../services/Auth';
import {
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import { Institution, InstitutionPeopleDto } from '../../../common/Types';
import React, { useEffect, useState } from 'react';
import { Button } from '../../../components';
import { Countries, InstitutionPersonRole, States } from '../../../common/Data';
import { formatPhoneNumber } from '../../../common/Utils';
import { institutionService } from '../../../services/InstitutionService';
import { personnelService } from '../../../services/Personnel';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';

interface InstitutionBillingContactProps {
    institution: Institution;
    type: InstitutionPersonRole;
    personnel: InstitutionPeopleDto[];
}

const InstitutionBillingContact = (props: InstitutionBillingContactProps) => {
    const [personnel, setPersonnel] = useState<InstitutionPeopleDto[]>(props.personnel);
    const [person, setPerson] = useState<InstitutionPeopleDto>();
    const [editing, setEditing] = useState<boolean>(false);
    const [showModal, setModal] = useState<boolean>(false);

    const { handleSubmit, register, reset, control } = useForm();

    const toggleModal = () => setModal(!showModal);

    const emptyPerson = {
        institutionId: props.institution.institutionId,
        institutionPeopleId: 0,
        peopleId: 0,
        programId: null,
        roleId: props.type,
        dateCreated: new Date(),
        role: {
            roleId: props.type,
            title: InstitutionPersonRole[props.type],
        },
        active: true,
        people: {
            active: true,
            editedBy: '00000000-0000-0000-0000-000000000000',
            createdBy: '00000000-0000-0000-0000-000000000000',
            dateCreated: '',
            lastEdited: '',
            credentials: '',
            email: '',
            extension: '',
            firstName: '',
            lastName: '',
            peopleId: 0,
            phone: '',
            salutations: '',
            title: '',
        },
    } as InstitutionPeopleDto;

    const reloadPersonnelFromServer = () => {
        if (props.institution) {
            personnelService
                .getAmsPersonnelByInsitutionId(props.institution.institutionId)
                .then((results) => {
                    const pers = results.find((r) => r.roleId === props.type && r.people);

                    if (pers) {
                        setPersonnel([...results.filter((r) => !r.programId)]);
                    } else {
                        institutionService
                            .getInstitutionBillingContact(props.institution.institutionId)
                            .then((bc) => {
                                if (bc) {
                                    setPersonnel([
                                        ...results.filter((r) => !r.programId),
                                        {
                                            ...bc,
                                            roleId: InstitutionPersonRole['Billing Contact'],
                                        },
                                    ]);
                                } else {
                                    setPersonnel([]);
                                }
                            })
                            .catch(() => {
                                toast.error('Error loading billing contact');
                            });
                    }
                })
                .catch(() => {
                    toast.error('Error loading personnel');
                });
        }
    };

    useEffect(() => {
        const pers = personnel.find((r) => r.roleId === props.type && r.people && r.active);

        if (pers) {
            setPerson({ ...pers });
            reset(pers);
        } else {
            setPerson({ ...emptyPerson });
            reset({ ...emptyPerson });
        }
        setEditing(false);
        setModal(false);
    }, [setPerson, props, reset, personnel]);

    const cancelForm = () => {
        const pers = personnel.find((r) => r.roleId === props.type && r.people && r.active);

        if (pers) {
            setPerson({ ...pers });
            reset(pers);
            setEditing(false);
        } else {
            setPerson({ ...emptyPerson });
            reset({ ...emptyPerson });
        }
    };

    const submitForm = (values) => {
        const toastId = toast.info('Saving person...');

        institutionService
            .saveInstitutionPerson(new InstitutionPeopleDto(values))
            .then((result) => {
                reloadPersonnelFromServer();
                toast.update(toastId, {
                    type: 'success',
                    render: 'Person saved successfully',
                });
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Error saving person',
                });
            });
    };

    const removePerson = () => {
        if (person) {
            const toastId = toast.info('Removing person...');

            institutionService
                .removeInstitutionPerson(person)
                .then((result) => {
                    reloadPersonnelFromServer();
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Person removed successfully',
                    });
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error removing person',
                    });
                });
        }
    };

    const isPersonBillingContact = (): boolean => {
        if (person && personnel) {
            return (
                personnel.filter(
                    (ip) =>
                        ip.institutionPeopleId === person.institutionPeopleId &&
                        ip.roleId !== InstitutionPersonRole['Billing Contact'],
                ).length > 0
            );
        }

        return false;
    };

    const actualBillingContactRole = (): string => {
        if (person && personnel) {
            const bcPerson = personnel.find(
                (ip) =>
                    ip.institutionPeopleId === person.institutionPeopleId &&
                    ip.roleId !== InstitutionPersonRole['Billing Contact'],
            );

            if (bcPerson && bcPerson.roleId) {
                return InstitutionPersonRole[bcPerson.roleId];
            }
        }

        return '';
    };

    const startNewBillingContact = () => {
        setPerson({ ...emptyPerson });
        reset({ ...emptyPerson });
        setEditing(!editing);
    };

    const editedByInfo =
        person?.people?.editedByString && person?.people?.editedByString.indexOf('{') > -1
            ? JSON.parse(person?.people?.editedByString)
            : undefined;

    return (
        <>
            <Form className={'flex-grow-1 d-flex'} onSubmit={handleSubmit(submitForm)}>
                <Card className={'align-self-stretch flex-grow-1'}>
                    <CardBody>
                        <h4 className={'card-title d-flex align-items-center justify-content-between'}>
                            <span>{InstitutionPersonRole[props.type]}</span>
                        </h4>
                        <input type={'hidden'} ref={register()} name={'institutionPeopleId'} />
                        <input type={'hidden'} ref={register()} name={'institutionId'} />
                        <input type={'hidden'} ref={register()} name={'programId'} />
                        <input type={'hidden'} ref={register()} name={'peopleId'} />
                        <input type={'hidden'} ref={register()} name={'roleId'} />
                        <input type={'hidden'} ref={register()} name={'dateCreated'} />
                        <input type={'hidden'} ref={register()} name={'people.peopleId'} />
                        <input type={'hidden'} ref={register()} name={'people.createdBy'} />
                        <input type={'hidden'} ref={register()} name={'people.dateCreated'} />
                        <input type={'hidden'} ref={register()} name={'people.lastEdited'} />
                        <input type={'hidden'} ref={register()} name={'people.editedBy'} />
                        <input type={'hidden'} ref={register()} name={'people.active'} />

                        <Row>
                            <Col>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Job Title</Label>
                                        {editing && (
                                            <>
                                                <Input innerRef={register()} name={'people.title'} type={'text'} />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.title}</span>}
                                    </Col>
                                </FormGroup>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Salutations</Label>
                                        {editing && (
                                            <>
                                                <Input
                                                    innerRef={register()}
                                                    name={'people.salutations'}
                                                    type={'text'}
                                                />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.salutations}</span>}
                                    </Col>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Credentials</Label>
                                        {editing && (
                                            <>
                                                <Input
                                                    innerRef={register()}
                                                    name={'people.credentials'}
                                                    type={'text'}
                                                />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.credentials}</span>}
                                    </Col>
                                </FormGroup>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>First Name</Label>
                                        {editing && (
                                            <>
                                                <Input innerRef={register()} name={'people.firstName'} type={'text'} />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.firstName}</span>}
                                    </Col>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Last Name</Label>
                                        {editing && (
                                            <>
                                                <Input innerRef={register()} name={'people.lastName'} type={'text'} />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.lastName}</span>}
                                    </Col>
                                </FormGroup>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Phone</Label>
                                        {editing && (
                                            <>
                                                <Input innerRef={register()} name={'people.phone'} type={'text'} />
                                            </>
                                        )}
                                        {!editing && (
                                            <div>
                                                {formatPhoneNumber(person?.people?.phone)}{' '}
                                                {person?.people?.extension ? `ext ${person?.people?.extension}` : ''}
                                            </div>
                                        )}
                                    </Col>
                                    {editing && (
                                        <Col className={'d-flex flex-column'}>
                                            <Label>Extension</Label>
                                            {editing && (
                                                <>
                                                    <Input
                                                        innerRef={register()}
                                                        name={'people.extension'}
                                                        type={'text'}
                                                    />
                                                </>
                                            )}
                                        </Col>
                                    )}
                                </FormGroup>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Email</Label>
                                        {editing && (
                                            <>
                                                <Input
                                                    innerRef={register({
                                                        required:
                                                            InstitutionPersonRole['Program Director'] === props.type
                                                                ? true
                                                                : false,
                                                    })}
                                                    name={'people.email'}
                                                    type={'text'}
                                                />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.email}</span>}
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        {person &&
                            (person.peopleId || 0) > 0 &&
                            (person.people?.lastEdited || person.people?.editedBy) && (
                                <Row>
                                    <Col className="font-italic">
                                        <span>{`Last edited`}</span>
                                        {editedByInfo && <span>{` by ${editedByInfo.name}`}</span>}
                                        {person.people?.lastEdited && (
                                            <span>{` on ${moment
                                                .utc(person.people?.lastEdited)
                                                .local()
                                                .format('MM/DD/YYYY [at] hh:mm A')}`}</span>
                                        )}
                                    </Col>
                                </Row>
                            )}
                        <Row className={'mt-2'}>
                            <Col>
                                {isPersonBillingContact() && (
                                    <label className={`font-weight-bold text-center`}>
                                        {`The billing contact for this institution is calculated as 
                                        the ${actualBillingContactRole()}. To update the billing 
                                        contact, please either update the ${actualBillingContactRole()}, 
                                        or click to add a specific billing contact.`}
                                    </label>
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                    {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                        <CardFooter className={'bg-white border-0 p-0'}>
                            <ButtonGroup className={'w-100'}>
                                {!editing && isPersonBillingContact() && (
                                    <Button color={'primary'} type={'button'} onClick={() => startNewBillingContact()}>
                                        <i className={'mdi mdi-account-plus'} />
                                        <span className={'ml-1'}>{`Add Different Billing Contact`}</span>
                                    </Button>
                                )}
                                {!editing && !isPersonBillingContact() && person && person.institutionPeopleId > 0 && (
                                    <>
                                        <Button color={'info'} type={'button'} onClick={() => setEditing(!editing)}>
                                            <i className={'mdi mdi-pencil'} />
                                            <span className={'ml-1'}>Edit Person</span>
                                        </Button>
                                        <Button color={'danger'} type={'button'} onClick={toggleModal}>
                                            <i className={'mdi mdi-delete'} />
                                            <span className={'ml-1'}>Remove Person</span>
                                        </Button>
                                    </>
                                )}
                                {editing && (
                                    <>
                                        <Button color={'success'} type={'submit'}>
                                            <i className={' mdi mdi-content-save'} />
                                            <span className={'ml-1'}>Submit</span>
                                        </Button>
                                        <Button color={'white'} type={'button'} onClick={() => cancelForm()}>
                                            <span className={'ml-1'}>Cancel</span>
                                        </Button>
                                    </>
                                )}
                            </ButtonGroup>
                        </CardFooter>
                    )}
                </Card>
            </Form>

            {person && person.people && person.people.peopleId > 0 && (
                <Modal isOpen={showModal} toggle={toggleModal} centered>
                    <ModalHeader>{`Confirm Remove ${person.people.firstName} ${person.people.lastName} (${
                        InstitutionPersonRole[props.type]
                    })`}</ModalHeader>
                    <ModalBody>
                        <p>
                            {`You have selected to remove ${person.people.firstName} ${
                                person.people.lastName
                            } from the role of ${InstitutionPersonRole[props.type]} at ${
                                props.institution.title
                            }. To continue, please click the Confirm button below.`}
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <div className={'d-flex justify-content-between flex-grow-1'}>
                            <Button color={'primary'} onClick={() => removePerson()} type={'button'}>
                                Confirm
                            </Button>
                            <Button color={'secondary'} onClick={toggleModal} type={'button'}>
                                Cancel
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

export default InstitutionBillingContact;
