import { AmsPermissionClaimType, authService } from '../../services/Auth';
import {
    AuspiceDto,
    Institution as InstitutionModel,
    InstitutionTypeDto,
    InstitutionalAccreditor,
} from '../../common/Types';
import { Button, FormError, Input, InstitutionNameHistory } from '../../components';
import { Card, CardBody, CardFooter, Col, Form, FormGroup, Label, Modal, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Redirect } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { formatPhoneNumber } from '../../common/Utils';
import { institutionService } from '../../services/InstitutionService';
import { systemAdministration } from '../../services/SystemAdministration';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { Countries, States } from '../../common/Data';

const Institution = (props) => {
    const [editing, setEditing] = useState(false);
    const { handleSubmit, register, errors, watch, setValue } = useForm();
    const [institution, setInstitution] = useState<InstitutionModel>(props.institution);
    const [institutionTypes, setInstitutionTypes] = useState<InstitutionTypeDto[]>();
    const [accreditors, setAccreditors] = useState<InstitutionalAccreditor[]>();
    const [auspices, setAuspices] = useState<AuspiceDto[]>();

    const [showNameHistory, setShowNameHistory] = useState<boolean>(false);
    const toggleShowNameHistory = () => setShowNameHistory(!showNameHistory);

    const [showRemove, setShowRemove] = useState<boolean>(false);
    const toggleShowRemove = () => setShowRemove(!showRemove);
    const [redirectToList, setRedirectToList] = useState<boolean>(false);
    const [isInternational, setIsInternational] = useState<boolean>(false);

    const country = watch('country');

    useEffect(() => {
        if (country && country !== 'United States of America') {
            setIsInternational(true);
        } else {
            setIsInternational(false);
        }
    }, [country, setValue]);

    useEffect(() => {
        setInstitution(props.institution);

        const loadTypes = () => {
            institutionService
                .getTypes()
                .then((types) => {
                    setInstitutionTypes(
                        types.filter((t) => t.isActive === true).sort((a, b) => a.title.localeCompare(b.title)),
                    );
                })
                .catch(() => {
                    toast.error('Error loading institution types');
                });
        };

        const loadAuspices = () => {
            institutionService
                .getAuspice()
                .then((aus) => {
                    setAuspices(aus.filter((a) => a.isActive === true).sort((a, b) => a.title.localeCompare(b.title)));
                })
                .catch(() => {
                    toast.error('Error loading auspices');
                });
        };

        const loadAccreditors = () => {
            systemAdministration
                .getInstitutionalAccreditors()
                .then((results) => {
                    setAccreditors([
                        ...results.filter((a) => a.isActive === true).sort((a, b) => a.title.localeCompare(b.title)),
                    ]);
                })
                .catch(() => {
                    toast.error('Error loading institutional accreditors');
                });
        };

        loadTypes();
        loadAuspices();
        loadAccreditors();
    }, [setInstitution, props.institution, setInstitutionTypes, setAuspices]);

    const onSubmit = (formData) => {
        const toastId = toast.info('Saving institution...');
        institutionService
            .saveInstitutions(new InstitutionModel(formData))
            .then((response) => {
                setInstitution(response);
                setEditing(false);
                toast.update(toastId, {
                    type: 'success',
                    render: 'Institution Saved.',
                });
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Failed to save institution.',
                });
            });
    };

    const onDeactivate = () => {
        const toastId = toast.info('Deactivating institution...');
        institutionService
            .saveInstitutions({
                ...institution,
                active: false,
            })
            .then(() => {
                toast.update(toastId, {
                    type: 'success',
                    render: 'Institution deactivated successfully',
                });
                setRedirectToList(true);
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Error encountered deactivating institution',
                });
            });
    };

    const editedByInfo =
        institution.editedByString && institution.editedByString.indexOf('{') > -1
            ? JSON.parse(institution.editedByString)
            : undefined;

    if (redirectToList) {
        return <Redirect to={`/institutions`} />;
    } else {
        return (
            <>
                {!institution && (
                    <>
                        <div>
                            <Skeleton count={2} />
                        </div>
                        <div>
                            <Skeleton count={2} />
                        </div>
                        <div>
                            <Skeleton count={2} />
                        </div>
                        <div>
                            <Skeleton count={2} />
                        </div>
                        <div>
                            <Skeleton count={2} />
                        </div>
                    </>
                )}
                {institution && (
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        {!editing && (
                            <FormGroup>
                                <Label>{`Insitution Id`}</Label>
                                <div>{institution.institutionId}</div>
                            </FormGroup>
                        )}
                        <FormGroup>
                            <Label for="title">
                                <span>{`Name`}</span>
                                {!editing && (
                                    <Button
                                        className={'ml-3 btn-sm'}
                                        type={'button'}
                                        onClick={() => toggleShowNameHistory()}
                                    >
                                        <i className={'mdi mdi-history'} />
                                        <span className={'ml-1'}>{`View Name History`}</span>
                                    </Button>
                                )}
                            </Label>
                            {editing && (
                                <>
                                    <Input
                                        type="text"
                                        name="title"
                                        id="title"
                                        placeholder="Name"
                                        innerRef={register({ required: true })}
                                        defaultValue={institution.title}
                                    />
                                    <FormError errors={errors} name="title" message="Name is required" />
                                </>
                            )}
                            {!editing && (
                                <div>
                                    <span>{institution.title}</span>
                                </div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label for="institutionTypeId">Type</Label>
                            {editing && (
                                <Input
                                    type="select"
                                    name="institutionTypeId"
                                    id="institutionTypeId"
                                    innerRef={register()}
                                    defaultValue={institution.institutionTypeId}
                                >
                                    {institutionTypes &&
                                        institutionTypes.map((t) => (
                                            <option key={t.institutionTypeId} value={t.institutionTypeId}>
                                                {t.title}
                                            </option>
                                        ))}
                                </Input>
                            )}
                            {!editing && (
                                <div>
                                    {
                                        institutionTypes?.find(
                                            (t) => t.institutionTypeId === institution.institutionTypeId,
                                        )?.title
                                    }
                                </div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label for="auspiceId">Control</Label>
                            {editing && (
                                <Input
                                    type="select"
                                    name="auspiceId"
                                    id="auspiceId"
                                    innerRef={register()}
                                    defaultValue={institution.auspiceId}
                                >
                                    {auspices &&
                                        auspices.map((a) => (
                                            <option key={a.auspiceID} value={a.auspiceID}>
                                                {a.title}
                                            </option>
                                        ))}
                                </Input>
                            )}
                            {!editing && (
                                <div>{auspices?.find((a) => a.auspiceID === institution.auspiceId)?.title}</div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label for="institutionalAccreditorId">Institutional Accreditor</Label>
                            {editing && (
                                <Input
                                    type="select"
                                    name="institutionalAccreditorId"
                                    id="institutionalAccreditorId"
                                    innerRef={register()}
                                    defaultValue={institution.institutionalAccreditorId}
                                >
                                    <option value={-1}>{`Not Applicable`}</option>
                                    {accreditors &&
                                        accreditors.map((a) => (
                                            <option
                                                key={a.institutionalAccreditorId}
                                                value={a.institutionalAccreditorId}
                                            >
                                                {a.title}
                                            </option>
                                        ))}
                                </Input>
                            )}
                            {!editing && (
                                <div>
                                    {
                                        accreditors?.find(
                                            (a) =>
                                                a.institutionalAccreditorId === institution.institutionalAccreditorId,
                                        )?.title
                                    }
                                </div>
                            )}
                        </FormGroup>
                        <div>
                            {editing && (
                                <>
                                    <div className="d-inline-block" style={{ width: '66%' }}>
                                        <div className="d-inline-block" style={{ width: '66%' }}>
                                            <FormGroup>
                                                <Label for="phone">Phone</Label>
                                                <Input
                                                    type="text"
                                                    name="phone"
                                                    id="phone"
                                                    placeholder="##########"
                                                    innerRef={register()}
                                                    defaultValue={institution.phone ?? undefined}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="d-inline-block p-1" style={{ width: '33%' }}>
                                            <FormGroup>
                                                <Label for="extension">Ext</Label>
                                                <Input
                                                    type="text"
                                                    name="extension"
                                                    id="extension"
                                                    placeholder="####"
                                                    innerRef={register()}
                                                    defaultValue={institution.extension ?? undefined}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>

                                    <div className="d-inline-block" style={{ width: '33%' }}>
                                        <FormGroup>
                                            <Label for="fax">Fax</Label>
                                            <Input
                                                type="text"
                                                name="fax"
                                                id="fax"
                                                placeholder="##########"
                                                innerRef={register()}
                                                defaultValue={institution.fax ?? undefined}
                                            />
                                        </FormGroup>
                                    </div>
                                </>
                            )}
                            {!editing && (
                                <>
                                    <FormGroup>
                                        <Row>
                                            <Col xs={7}>
                                                <Label>Phone</Label>
                                                <div>
                                                    {formatPhoneNumber(institution?.phone)}{' '}
                                                    {institution.extension ? `ext ${institution.extension}` : ''}
                                                </div>
                                            </Col>
                                            <Col>
                                                <Label>Fax:</Label>
                                                <div>
                                                    {institution?.fax ? formatPhoneNumber(institution?.fax) : 'N/A'}
                                                </div>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </>
                            )}
                        </div>
                        <FormGroup>
                            <Label for="webSite">Website</Label>
                            {editing && (
                                <Input
                                    type="text"
                                    name="webSite"
                                    id="webSite"
                                    placeholder="Website"
                                    innerRef={register()}
                                    defaultValue={institution.webSite ?? undefined}
                                />
                            )}
                            {!editing && (
                                <div>
                                    {institution.webSite && (
                                        <a
                                            href={`${institution?.webSite?.indexOf('http://') > -1 ? '' : 'http://'}${
                                                institution.webSite
                                            }`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {institution.webSite}&nbsp;<i className={'remixicon-links-line'}></i>
                                        </a>
                                    )}
                                    {!institution.webSite && 'N/A'}
                                </div>
                            )}
                        </FormGroup>
                        {editing && (
                            <FormGroup>
                                <Label for="address">Address</Label>
                                <div>
                                    <Input
                                        type="text"
                                        name="address"
                                        id="address"
                                        placeholder="Address"
                                        innerRef={register()}
                                        defaultValue={institution.address ?? undefined}
                                    />
                                </div>
                                <div className="mt-1">
                                    <Input
                                        type="text"
                                        name="address2"
                                        id="address2"
                                        placeholder="Address 2"
                                        innerRef={register()}
                                        defaultValue={institution.address2 ?? undefined}
                                    />
                                </div>
                                <div className="mt-1">
                                    <Input
                                        type="text"
                                        name="address3"
                                        id="address3"
                                        placeholder="Address 3"
                                        innerRef={register()}
                                        defaultValue={institution.address3 ?? undefined}
                                    />
                                </div>
                                <div className="mt-1">
                                    <Input
                                        type="text"
                                        name="address4"
                                        id="address4"
                                        placeholder="Address 4"
                                        innerRef={register()}
                                        defaultValue={institution.address4 ?? undefined}
                                    />
                                </div>
                                <div className="mt-1">
                                    <div className="d-inline-block w-50">
                                        <Input
                                            type="text"
                                            name="city"
                                            id="city"
                                            placeholder="City"
                                            innerRef={register()}
                                            defaultValue={institution.city ?? undefined}
                                        />
                                    </div>
                                    <div className="d-inline-block w-50">
                                        {!isInternational && (
                                            <Input
                                                type={'select'}
                                                name="state"
                                                id="state"
                                                placeholder="State"
                                                defaultValue={institution.state || undefined}
                                                innerRef={register()}
                                            >
                                                <option value={undefined}>{``}</option>
                                                {States &&
                                                    States.map((s) => (
                                                        <option key={s.abbreviation} value={s.abbreviation}>
                                                            {s.abbreviation}
                                                        </option>
                                                    ))}
                                            </Input>
                                        )}
                                        {isInternational && (
                                            <Input
                                                type="text"
                                                name="state"
                                                id="state"
                                                placeholder="State"
                                                innerRef={register()}
                                                defaultValue={institution.state ?? undefined}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="mt-1">
                                    <div className="d-inline-block w-25">
                                        <Input
                                            type="text"
                                            name="zip"
                                            id="zip"
                                            placeholder={isInternational ? `Postal Code` : `Zip`}
                                            innerRef={register()}
                                            defaultValue={institution.zip ?? undefined}
                                        />
                                    </div>
                                    <div className="d-inline-block w-75 p-1">
                                        <Input
                                            type={'select'}
                                            name="country"
                                            id="country"
                                            placeholder="Country"
                                            defaultValue={institution.country || undefined}
                                            innerRef={register()}
                                        >
                                            <option value={undefined}>{``}</option>
                                            {Countries &&
                                                Countries.map((c) => (
                                                    <option key={c} value={c}>
                                                        {c}
                                                    </option>
                                                ))}
                                        </Input>
                                    </div>
                                </div>
                            </FormGroup>
                        )}
                        {!editing && (
                            <>
                                <Row className="mt-2">
                                    <Col>
                                        <Label>Address</Label>
                                        <div>{institution?.address || ''}</div>
                                        {institution?.address2 && <div>{institution?.address2}</div>}
                                        {institution?.address3 && <div>{institution?.address3}</div>}
                                        {institution?.address4 && <div>{institution?.address4}</div>}
                                        <div>{`${institution?.city || ''}, ${institution?.state || ''} ${
                                            institution?.zip || ''
                                        }`}</div>
                                        <div>{institution?.country || ''}</div>
                                    </Col>
                                </Row>
                                {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                                    <Row className="mt-4">
                                        <Col>
                                            <Button onClick={() => setEditing(!editing)}>Edit</Button>
                                        </Col>
                                    </Row>
                                )}
                                {(institution.lastEdited || institution.editedBy) && (
                                    <Row className="mt-2">
                                        <Col className="font-italic">
                                            <span>{`Last edited`}</span>
                                            {editedByInfo && <span>{` by ${editedByInfo.name}`}</span>}
                                            {institution.lastEdited && (
                                                <span>{` on ${moment
                                                    .utc(institution.lastEdited)
                                                    .local()
                                                    .format('MM/DD/YYYY [at] hh:mm A')}`}</span>
                                            )}
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                        <input
                            type="hidden"
                            name="institutionId"
                            defaultValue={institution.institutionId}
                            ref={register()}
                        />
                        <input type="hidden" name="ceoId" defaultValue={`${institution.ceoId}`} ref={register()} />
                        <input
                            type="hidden"
                            name="createdBy"
                            defaultValue={`${institution.createdBy || ''}`}
                            ref={register()}
                        />
                        <input
                            type="hidden"
                            name="dateCreated"
                            defaultValue={`${institution.dateCreated}`}
                            ref={register()}
                        />
                        <input
                            type="hidden"
                            name="lastEdited"
                            defaultValue={`${institution.lastEdited}`}
                            ref={register()}
                        />
                        <input
                            type="hidden"
                            name="editedBy"
                            defaultValue={`${institution.editedBy || ''}`}
                            ref={register()}
                        />
                        <input
                            type="hidden"
                            name="preserveBillingInformation"
                            defaultValue={`${institution.preserveBillingInformation}`}
                            ref={register()}
                        />
                        <input
                            type="hidden"
                            name="synchronizeCeoInstitution"
                            defaultValue={`${institution.synchronizeCeoInstitution}`}
                            ref={register()}
                        />
                        <input type="hidden" name="active" defaultValue={`${institution.active}`} ref={register()} />

                        {editing && (
                            <>
                                <Row className="mt-3">
                                    <Col className={`d-flex justify-content-between`}>
                                        <div>
                                            <Button className={'mr-2'} type="submit">{`Save`}</Button>
                                            <Button outline onClick={() => setEditing(!editing)}>
                                                {`Cancel`}
                                            </Button>
                                        </div>
                                        <Button type={'button'} color={'danger'} onClick={() => toggleShowRemove()}>
                                            {`Deactivate`}
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form>
                )}
                {institution && (
                    <Modal isOpen={showNameHistory} toggle={toggleShowNameHistory} centered>
                        <InstitutionNameHistory institutionId={institution.institutionId} />
                    </Modal>
                )}
                <Modal isOpen={showRemove} toggle={toggleShowRemove} centered>
                    <Card className={'mb-0'}>
                        <CardBody>
                            <h4 className={'card-title'}>{`Deactivate Institution`}</h4>
                            <p>
                                {`To confirm the deactivation of this institution, please click the 'Confirm' button below.`}
                            </p>
                        </CardBody>
                        <CardFooter>
                            <Button type={'button'} color={'primary'} className={'mr-2'} onClick={() => onDeactivate()}>
                                {`Confirm`}
                            </Button>
                            <Button type={'button'} color={'secondary'} onClick={() => toggleShowRemove()}>
                                {`Cancel`}
                            </Button>
                        </CardFooter>
                    </Card>
                </Modal>
            </>
        );
    }
};

export default Institution;
