import { AmsPermissionClaimType, authService } from '../../../services/Auth';
import {
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import {
    IdentityUser,
    InstitutionPeopleDto,
    ProfessionDto,
    Program,
    ProgramDirectorUser,
    ProgramDto,
} from '../../../common/Types';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Button } from '../../../components';
import { Countries, InstitutionPersonRole, States } from '../../../common/Data';
import { NavLink } from 'react-router-dom';
import { formatPhoneNumber } from '../../../common/Utils';
import { institutionService } from '../../../services/InstitutionService';
import { personnelService } from '../../../services/Personnel';
import { professionService } from '../../../services/ProfessionAdministration';
import { securityService } from '../../../services/SecurityService';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';

interface ProgramPersonnelProps {
    program: Program;
    type: InstitutionPersonRole;
    personnel: InstitutionPeopleDto[];
    otherPrograms: ProgramDto[];
}

const ProgramPersonnel = (props: ProgramPersonnelProps) => {
    const [rolePeople, setRolePeople] = useState<InstitutionPeopleDto[]>(
        props.personnel.filter((r) => r.roleId === props.type && r.programId === props.program.programId && r.people),
    );
    const [person, setPerson] = useState<InstitutionPeopleDto>();
    const [otherPrograms, setOtherPrograms] = useState<ProgramDto[]>([]);
    const [editing, setEditing] = useState<boolean>(false);
    const [showModal, setModal] = useState<boolean>(false);
    const [identity, setIdentity] = useState<IdentityUser>();
    const [profession, setProfession] = useState<ProfessionDto>();

    const { handleSubmit, register, reset, control } = useForm();

    const toggleModal = () => setModal(!showModal);

    const reloadPersonnelFromServer = () => {
        if (props.program) {
            personnelService
                .getAmsPersonnelByInsitutionId(props.program.institutionId)
                .then((results) => {
                    setRolePeople([
                        ...results.filter(
                            (r) =>
                                (!r.programId || r.programId === props.program.programId) &&
                                r.active === true &&
                                r.people?.active === true &&
                                r.roleId === props.type,
                        ),
                    ]);
                    setModal(false);
                })
                .catch(() => {
                    toast.error('Error loading personnel');
                });
        }
    };

    useEffect(() => {
        if (rolePeople.length > 0) {
            if (!person || rolePeople.find((rp) => rp.institutionPeopleId !== person?.institutionPeopleId)) {
                setPerson({ ...rolePeople[0] });
                reset(rolePeople[0]);
            } else if (person) {
                const selected = rolePeople.find((rp) => rp.institutionPeopleId === person.institutionPeopleId);
                if (selected) {
                    setPerson({ ...selected });
                    reset(selected);
                }
            }
            setEditing(false);
        } else {
            const emptyPerson = {
                institutionId: props.program.institutionId,
                institutionPeopleId: 0,
                peopleId: 0,
                programId: props.program.programId,
                roleId: props.type,
                dateCreated: new Date(),
                role: {
                    roleId: props.type,
                    title: InstitutionPersonRole[props.type],
                },
                active: true,
                people: {
                    active: true,
                    editedBy: '00000000-0000-0000-0000-000000000000',
                    createdBy: '00000000-0000-0000-0000-000000000000',
                    dateCreated: '',
                    lastEdited: '',
                    credentials: '',
                    email: '',
                    extension: '',
                    firstName: '',
                    lastName: '',
                    peopleId: 0,
                    phone: '',
                    salutations: '',
                    title: '',
                },
            } as InstitutionPeopleDto;

            setPerson(emptyPerson);
            reset(emptyPerson);
        }
    }, [setPerson, props, reset, rolePeople]);

    useEffect(() => {
        if (person && props.otherPrograms) {
            const samePersonSameRoleOtherPrograms = props.personnel.filter(
                (r) =>
                    r.roleId === props.type &&
                    r.peopleId === person.peopleId &&
                    r.programId !== props.program.programId,
            );

            setOtherPrograms([
                ...props.otherPrograms.filter((op) =>
                    samePersonSameRoleOtherPrograms.find((spop) => spop.programId === op.programId),
                ),
            ]);

            if (
                props.type === InstitutionPersonRole['Program Director'] &&
                person.people?.email &&
                authService.hasPermission(AmsPermissionClaimType, 'systemadmin')
            ) {
                securityService
                    .loadProgramDirector(person.people.email, props.program.programId)
                    .then((result) => {
                        setIdentity(result);
                    })
                    .catch(() => {
                        toast.warning('Unable to validate Program Director login.');
                    });
            }
        }
    }, [setOtherPrograms, person, props, setIdentity]);

    useEffect(() => {
        const loadProfession = () => {
            if (props.program.professionId) {
                professionService
                    .getProfesssion(props.program.professionId)
                    .then((prof) => {
                        setProfession(prof);
                    })
                    .catch(() => {
                        toast.error('Error loading profession detail.');
                    });
            }
        };

        loadProfession();
    }, [props.program.professionId, setProfession]);

    const cancelForm = () => {
        setEditing(!editing);
        reset(person);
    };

    const createProgramDirectorLogin = () => {
        if (person?.people) {
            const toastId = toast.info('Creating Program Director login...');

            securityService
                .updateProgramDirector(
                    new ProgramDirectorUser({
                        ...person.people,
                        programId: props.program.programId,
                    }),
                )
                .then(() => {
                    reloadPersonnelFromServer();
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Login created successfully',
                    });
                })
                .catch(() => {
                    toast.error('Error creating login');
                });
        }
    };

    const removeProgramDirectorLogin = () => {
        if (person?.people) {
            const toastId = toast.info('Removing Program Director login...');

            securityService
                .removeProgramDirector(
                    new ProgramDirectorUser({
                        ...person.people,
                        programId: props.program.programId,
                    }),
                )
                .then(() => {
                    reloadPersonnelFromServer();
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Login removed successfully',
                    });
                })
                .catch(() => {
                    toast.error('Error removing login');
                });
        }
    };

    const sendPasswordResetEmail = () => {
        if (identity?.id) {
            const toastId = toast.info('Sending password reset email...');

            securityService
                .initiatePasswordReset(identity.id)
                .then(() => {
                    reloadPersonnelFromServer();
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Password reset email sent successfully',
                    });
                })
                .catch(() => {
                    toast.error('Error sending password reset email');
                });
        }
    };

    const submitForm = (values) => {
        const toastId = toast.info('Saving person...');

        institutionService
            .saveInstitutionPerson(new InstitutionPeopleDto(values))
            .then((result) => {
                if (
                    props.type === InstitutionPersonRole['Program Director'] &&
                    profession?.armsUser === true &&
                    rolePeople.length <= 1
                ) {
                    securityService
                        .updateProgramDirector(
                            new ProgramDirectorUser({
                                ...new InstitutionPeopleDto(values).people,
                                programId: props.program.programId,
                            }),
                        )
                        .then(() => {
                            reloadPersonnelFromServer();
                            setEditing(!editing);
                            toast.update(toastId, {
                                type: 'success',
                                render: 'Person saved successfully',
                            });
                        })
                        .catch(() => {
                            toast.error('Error updating program director permissions');
                        });
                } else {
                    reloadPersonnelFromServer();
                    setEditing(!editing);
                    toast.update(toastId, {
                        type: 'success',
                        render: 'Person saved successfully',
                    });
                }
            })
            .catch(() => {
                toast.update(toastId, {
                    type: 'error',
                    render: 'Error saving person',
                });
            });
    };

    const removePerson = () => {
        if (person) {
            const toastId = toast.info('Removing person...');

            institutionService
                .removeInstitutionPerson(person)
                .then((result) => {
                    if (props.type === InstitutionPersonRole['Program Director']) {
                        securityService
                            .removeProgramDirector(
                                new ProgramDirectorUser({
                                    ...person.people,
                                    programId: props.program.programId,
                                }),
                            )
                            .then(() => {
                                reloadPersonnelFromServer();
                                toast.update(toastId, {
                                    type: 'success',
                                    render: 'Person removed successfully',
                                });
                            })
                            .catch(() => {
                                toast.error('Error updating program director permissions');
                            });
                    } else {
                        reloadPersonnelFromServer();
                        toast.update(toastId, {
                            type: 'success',
                            render: 'Person removed successfully',
                        });
                    }
                })
                .catch(() => {
                    toast.update(toastId, {
                        type: 'error',
                        render: 'Error removing person',
                    });
                });
        }
    };

    const canSendPasswordReset = (): boolean => {
        return (
            identity !== undefined && identity.email !== undefined && identity.email !== null && identity.email !== ''
        );
    };

    const isMissingIdentity = (): boolean => {
        return (
            props.type === InstitutionPersonRole['Program Director'] &&
            identity !== undefined &&
            (identity.email === null || identity.email === undefined || identity.email === '')
        );
    };

    const isCannotCreateLogin = (): boolean => {
        return (
            props.type === InstitutionPersonRole['Program Director'] &&
            !isMissingIdentity() &&
            person !== undefined &&
            person.institutionPeopleId > 0 &&
            (person.people?.email === undefined || person.people?.email === null)
        );
    };

    const editedByInfo =
        person?.people?.editedByString && person?.people?.editedByString.indexOf('{') > -1
            ? JSON.parse(person?.people?.editedByString)
            : undefined;

    return (
        <>
            <Form className={'flex-grow-1 d-flex'} onSubmit={handleSubmit(submitForm)}>
                <Card className={'align-self-stretch flex-grow-1'}>
                    {rolePeople.length > 1 && (
                        <CardHeader className={`bg-warning d-flex justify-content-between`}>
                            <div>
                                <h4 className={'mb-0'}>{InstitutionPersonRole[props.type]}</h4>
                            </div>
                            <div className={`d-flex flex-end align-items-center`}>
                                {rolePeople.map((rp) => (
                                    <Button
                                        key={rp.institutionPeopleId}
                                        type={'button'}
                                        className={`btn-sm ml-1`}
                                        onClick={() => {
                                            setPerson({ ...rp });
                                            reset({ ...rp });
                                        }}
                                        disabled={editing}
                                        color={`${
                                            person?.institutionPeopleId === rp.institutionPeopleId
                                                ? 'primary'
                                                : 'secondary'
                                        }`}
                                    >
                                        <span
                                            style={{ fontSize: '11px' }}
                                        >{`${rp.people?.firstName} ${rp.people?.lastName}`}</span>
                                    </Button>
                                ))}
                            </div>
                        </CardHeader>
                    )}
                    <CardBody>
                        <h4 className={'card-title d-flex align-items-center justify-content-between'}>
                            <span>{InstitutionPersonRole[props.type]}</span>
                            <div>
                                {canSendPasswordReset() && (
                                    <Button
                                        type={'button'}
                                        color={'warning'}
                                        className={'btn-sm'}
                                        onClick={() => sendPasswordResetEmail()}
                                    >{`Send Password Reset Email`}</Button>
                                )}
                                {canSendPasswordReset() && (
                                    <Button
                                        type={'button'}
                                        color={'danger'}
                                        className={'btn-sm ml-2'}
                                        onClick={() => removeProgramDirectorLogin()}
                                    >{`Remove Login`}</Button>
                                )}
                                {isMissingIdentity() && (
                                    <Button
                                        type={'button'}
                                        color={'primary'}
                                        className={'btn-sm'}
                                        onClick={() => createProgramDirectorLogin()}
                                    >{`Create Login`}</Button>
                                )}
                                {isCannotCreateLogin() && (
                                    <h6 className={'text-danger'}>{`Email is required for a login`}</h6>
                                )}
                            </div>
                        </h4>
                        <input type={'hidden'} ref={register()} name={'institutionPeopleId'} />
                        <input type={'hidden'} ref={register()} name={'institutionId'} />
                        <input type={'hidden'} ref={register()} name={'programId'} />
                        <input type={'hidden'} ref={register()} name={'peopleId'} />
                        <input type={'hidden'} ref={register()} name={'roleId'} />
                        <input type={'hidden'} ref={register()} name={'dateCreated'} />
                        <input type={'hidden'} ref={register()} name={'people.peopleId'} />
                        <input type={'hidden'} ref={register()} name={'people.createdBy'} />
                        <input type={'hidden'} ref={register()} name={'people.dateCreated'} />
                        <input type={'hidden'} ref={register()} name={'people.lastEdited'} />
                        <input type={'hidden'} ref={register()} name={'people.editedBy'} />
                        <input type={'hidden'} ref={register()} name={'people.active'} />

                        <Row>
                            <Col>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Job Title</Label>
                                        {editing && (
                                            <>
                                                <Input innerRef={register()} name={'people.title'} type={'text'} />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.title}</span>}
                                    </Col>
                                </FormGroup>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Salutations</Label>
                                        {editing && (
                                            <>
                                                <Input
                                                    innerRef={register()}
                                                    name={'people.salutations'}
                                                    type={'text'}
                                                />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.salutations}</span>}
                                    </Col>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Credentials</Label>
                                        {editing && (
                                            <>
                                                <Input
                                                    innerRef={register()}
                                                    name={'people.credentials'}
                                                    type={'text'}
                                                />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.credentials}</span>}
                                    </Col>
                                </FormGroup>
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>First Name</Label>
                                        {editing && (
                                            <>
                                                <Input innerRef={register()} name={'people.firstName'} type={'text'} />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.firstName}</span>}
                                    </Col>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Last Name</Label>
                                        {editing && (
                                            <>
                                                <Input innerRef={register()} name={'people.lastName'} type={'text'} />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.lastName}</span>}
                                    </Col>
                                </FormGroup>
                                {props.type && InstitutionPersonRole[props.type] === 'Program Director' && (
                                    <FormGroup className={'row'}>
                                        <Col className={'d-flex flex-column'}>
                                            <Label>Phone</Label>
                                            {editing && (
                                                <>
                                                    <Input innerRef={register()} name={'people.phone'} type={'text'} />
                                                </>
                                            )}
                                            {!editing && (
                                                <div>
                                                    {formatPhoneNumber(person?.people?.phone)}{' '}
                                                    {person?.people?.extension
                                                        ? `ext ${person?.people?.extension}`
                                                        : ''}
                                                </div>
                                            )}
                                        </Col>
                                        {editing && (
                                            <Col className={'d-flex flex-column'}>
                                                <Label>Extension</Label>
                                                {editing && (
                                                    <>
                                                        <Input
                                                            innerRef={register()}
                                                            name={'people.extension'}
                                                            type={'text'}
                                                        />
                                                    </>
                                                )}
                                            </Col>
                                        )}
                                    </FormGroup>
                                )}
                                <FormGroup className={'row'}>
                                    <Col className={'d-flex flex-column'}>
                                        <Label>Email</Label>
                                        {editing && (
                                            <>
                                                <Input
                                                    innerRef={register({
                                                        required:
                                                            InstitutionPersonRole['Program Director'] === props.type
                                                                ? true
                                                                : false,
                                                    })}
                                                    name={'people.email'}
                                                    type={'text'}
                                                />
                                            </>
                                        )}
                                        {!editing && <span>{person?.people?.email}</span>}
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        {otherPrograms && otherPrograms.length > 0 && (
                            <Row>
                                <Col className={'d-flex flex-column align-items-start'}>
                                    <Label>Assigned to Other Programs</Label>
                                    <div className={'d-flex flex-wrap'}>
                                        {otherPrograms.map((op) => (
                                            <NavLink
                                                key={op.programId}
                                                to={`/institution/${op.institutionId}/program/${op.programId}`}
                                                className={'btn btn-sm btn-secondary mb-2 mr-2'}
                                            >
                                                {op.title}
                                            </NavLink>
                                        ))}
                                    </div>
                                </Col>
                            </Row>
                        )}
                        {person &&
                            (person.peopleId || 0) > 0 &&
                            (person.people?.lastEdited || person.people?.editedBy) && (
                                <Row>
                                    <Col className="font-italic">
                                        <span>{`Last edited`}</span>
                                        {editedByInfo && <span>{` by ${editedByInfo.name}`}</span>}
                                        {person.people?.lastEdited && (
                                            <span>{` on ${moment
                                                .utc(person.people?.lastEdited)
                                                .local()
                                                .format('MM/DD/YYYY [at] hh:mm A')}`}</span>
                                        )}
                                    </Col>
                                </Row>
                            )}
                    </CardBody>
                    {authService.hasPermission(AmsPermissionClaimType, 'systemadmin') && (
                        <CardFooter className={'bg-white border-0 p-0'}>
                            <ButtonGroup className={'w-100'}>
                                {!editing && (
                                    <>
                                        {person && person.institutionPeopleId > 0 && (
                                            <>
                                                <Button
                                                    color={'info'}
                                                    type={'button'}
                                                    onClick={() => setEditing(!editing)}
                                                >
                                                    <i className={'mdi mdi-pencil'} />
                                                    <span className={'ml-1'}>Edit Person</span>
                                                </Button>
                                                <Button color={'danger'} type={'button'} onClick={toggleModal}>
                                                    <i className={'mdi mdi-delete'} />
                                                    <span className={'ml-1'}>Remove Person</span>
                                                </Button>
                                            </>
                                        )}
                                        {person && person.institutionPeopleId === 0 && (
                                            <Button color={'info'} type={'button'} onClick={() => setEditing(!editing)}>
                                                <i className={'mdi mdi-account-plus'} />
                                                <span className={'ml-1'}>Add Person</span>
                                            </Button>
                                        )}
                                    </>
                                )}
                                {editing && (
                                    <>
                                        <Button color={'success'} type={'submit'}>
                                            <i className={' mdi mdi-content-save'} />
                                            <span className={'ml-1'}>Submit</span>
                                        </Button>
                                        <Button color={'white'} type={'button'} onClick={() => cancelForm()}>
                                            <span className={'ml-1'}>Cancel</span>
                                        </Button>
                                    </>
                                )}
                            </ButtonGroup>
                        </CardFooter>
                    )}
                </Card>
            </Form>

            {person && person.people && person.people.peopleId > 0 && (
                <Modal isOpen={showModal} toggle={toggleModal} centered>
                    <ModalHeader>{`Confirm Remove ${person.people.firstName} ${person.people.lastName} (${
                        InstitutionPersonRole[props.type]
                    })`}</ModalHeader>
                    <ModalBody>
                        <p>
                            {`You have selected to remove ${person.people.firstName} ${
                                person.people.lastName
                            } from the role of ${InstitutionPersonRole[props.type]} at ${
                                props.program.title
                            }. To continue, please click the Confirm button below.`}
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <div className={'d-flex justify-content-between flex-grow-1'}>
                            <Button color={'primary'} onClick={() => removePerson()} type={'button'}>
                                Confirm
                            </Button>
                            <Button color={'secondary'} onClick={toggleModal} type={'button'}>
                                Cancel
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

export default ProgramPersonnel;
