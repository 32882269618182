import { APIUriAms, http, httpDownloadFile, httpFile } from './HttpHelper';
import {
    AccreditationActionTotalReportDto,
    AccreditedProgramDto,
    AdminProbationReportDto,
    DegreeByAuspiceReportDto,
    GeneralInformationDto,
    InactiveStatusReportDto,
    NumberOfActiveProgramsReportDto,
    ProbationStatusReportDto,
    ProfessionSearchCountReportDto,
    ProfitVsPublicDto,
    StandardReport,
    WithdrawStatusReportDto,
} from '../common/Types';
import { PagingDto } from '../types';
import moment from 'moment';

class ReportService {
    public getGeneralInfo = async (): Promise<GeneralInformationDto> => {
        const result = await http<GeneralInformationDto>(APIUriAms, `/report/general-info`, undefined, 'GET', null);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };
    public getAccreditedPrograms = async (
        paging: PagingDto,
        professionId?: number | null,
        statusIds?: number[] | [],
        effectiveDate?: string | Date | null,
        asOfEffectiveDate?: string | Date | null,
        initialAccreditationDate?: string | Date | null,
        institutionId?: number | null,
        institutionTypeId?: number | null,
        programName?: string | null,
        degreeTypes?: number[] | [],
        auspice?: number[] | [],
        city?: string | null,
        state?: string[] | [],
        distance?: boolean,
        professionLevelId?: number | null,
    ) => {
        const result = await http<AccreditedProgramDto[]>(
            APIUriAms,
            `/report/accredited-program?professionId=${professionId || ''}${
                statusIds && statusIds?.length > 0 ? '&statusIds=' + statusIds.join('&statusIds=') : ''
            }&effectiveDate=${effectiveDate || ''}&asOfEffectiveDate=${
                asOfEffectiveDate || ''
            }&initialAccreditationDate=${initialAccreditationDate || ''}&institutionId=${
                institutionId || ''
            }&institutionTypeId=${institutionTypeId || ''}&programName=${programName || ''}${
                degreeTypes && degreeTypes?.length > 0 ? '&degreeTypes=' + degreeTypes.join('&degreeTypes=') : ''
            }${auspice && auspice?.length > 0 ? '&auspice=' + auspice.join('&auspice=') : ''}&city=${city || ''}${
                state && state?.length > 0 ? '&state=' + state.join('&state=') : ''
            }&distance=${distance}&professionLevelId=${professionLevelId || ''}&pageNumber=${
                paging.pageNumber
            }&pageSize=${paging.pageSize}`,
            undefined,
            undefined,
            null,
        );
        const pagingHeader = result.headers.get('X-Pagination');
        const total = pagingHeader ? JSON.parse(pagingHeader).TotalCount : 0;
        if (result.parsedBody) {
            return {
                records: result.parsedBody,
                total: total,
            };
        } else {
            throw new Error(result.statusText);
        }
    };

    public exportAccreditedProgramReport = async (
        professionId?: number | null,
        statusIds?: number[] | [],
        effectiveDate?: string | Date | null,
        asOfEffectiveDate?: string | Date | null,
        initialAccreditationDate?: string | Date | null,
        institutionId?: number | null,
        institutionTypeId?: number | null,
        programName?: string | null,
        degreeTypes?: number[] | [],
        auspice?: number[] | [],
        city?: string | null,
        state?: string[] | [],
        distance?: boolean,
        professionLevelId?: number | null,
    ): Promise<Blob | undefined> => {
        const result = await httpDownloadFile(
            APIUriAms,
            `/report/export-accredited-program?professionId=${professionId || ''}${
                statusIds && statusIds?.length > 0 ? '&statusIds=' + statusIds.join('&statusIds=') : ''
            }&effectiveDate=${effectiveDate || ''}&asOfEffectiveDate=${
                asOfEffectiveDate || ''
            }&initialAccreditationDate=${initialAccreditationDate || ''}&institutionId=${
                institutionId || ''
            }&institutionTypeId=${institutionTypeId || ''}&programName=${programName || ''}${
                degreeTypes && degreeTypes?.length > 0 ? '&degreeTypes=' + degreeTypes.join('&degreeTypes=') : ''
            }${auspice && auspice?.length > 0 ? '&auspice=' + auspice.join('&auspice=') : ''}&city=${city || ''}${
                state && state?.length > 0 ? '&state=' + state.join('&state=') : ''
            }&distance=${distance}&professionLevelId=${professionLevelId || ''}`,
            undefined,
        );
        if (result.ok) {
            try {
                return result.parsedBody;
            } catch (ex) {
                throw ex;
            }
        } else {
            throw Error(result.statusText);
        }
    };
    public exportInstitutionContactReport = async (
        professionId?: number | null,
        roles?: number[] | [],
        statusIds?: number[] | [],
        states?: string[] | [],
        effectiveDate?: string | Date | null,
    ): Promise<Blob | undefined> => {
        const result = await httpDownloadFile(
            APIUriAms,
            `/report/export-institution-contacts?professionId=${professionId || ''}${
                roles && roles?.length > 0 ? '&roles=' + roles.join('&roles=') : ''
            }${statusIds && statusIds?.length > 0 ? '&statusIds=' + statusIds.join('&statusIds=') : ''}${
                states && states?.length > 0 ? '&state=' + states.join('&state=') : ''
            }&effectiveDate=${effectiveDate || ''}`,
            undefined,
        );
        if (result.ok) {
            try {
                return result.parsedBody;
            } catch (ex) {
                throw ex;
            }
        } else {
            throw Error(result.statusText);
        }
    };
    public getAccreditationActionTotalsReport = async (
        startDate: string | Date,
        endDate: string | Date,
        withConcentrations: boolean | undefined,
    ): Promise<AccreditationActionTotalReportDto> => {
        const result = await http<AccreditationActionTotalReportDto>(
            APIUriAms,
            `/report/accreditation-action-totals?startDate=${startDate}&endDate=${endDate}&withConcentrations=${withConcentrations}`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public exportRecommendationStandardsReport = async (report: StandardReport): Promise<Blob | undefined> => {
        const result = await httpDownloadFile(
            APIUriAms,
            `/report/export-recommendation-standards-report`,
            JSON.stringify(report),
            'POST',
            'application/json',
        );
        if (result.ok) {
            try {
                return result.parsedBody;
            } catch (ex) {
                throw ex;
            }
        } else {
            throw Error(result.statusText);
        }
    };

    public exportAccreditationActionTotalsReport = async (
        startDate: string | Date,
        endDate: string | Date,
        withConcentrations: boolean,
    ): Promise<Blob | undefined> => {
        const result = await httpDownloadFile(
            APIUriAms,
            `/report/export-accreditation-action-totals?startDate=${startDate}&endDate=${endDate}&withConcentrations=${withConcentrations}`,
            undefined,
        );
        if (result.ok) {
            try {
                return result.parsedBody;
            } catch (ex) {
                throw ex;
            }
        } else {
            throw Error(result.statusText);
        }
    };

    public getAdminProbationReport = async (): Promise<AdminProbationReportDto> => {
        const result = await http<AdminProbationReportDto>(
            APIUriAms,
            `/report/admin-probation`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public exportAdminProbationReport = async (): Promise<Blob | undefined> => {
        const result = await httpDownloadFile(APIUriAms, `/report/export-admin-probation`, undefined);
        if (result.ok) {
            try {
                return result.parsedBody;
            } catch (ex) {
                throw ex;
            }
        } else {
            throw Error(result.statusText);
        }
    };

    public getDegreesByAuspiceReport = async (
        degreeTypes?: number[] | [],
        auspice?: number[] | [],
    ): Promise<DegreeByAuspiceReportDto> => {
        const result = await http<DegreeByAuspiceReportDto>(
            APIUriAms,
            `/report/degrees-by-auspice?withConcentrations=${
                degreeTypes && degreeTypes?.length > 0 ? '&degreeTypes=' + degreeTypes.join('&degreeTypes=') : ''
            }${auspice && auspice?.length > 0 ? '&auspice=' + auspice.join('&auspice=') : ''}`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public exportDegreesByAuspiceReport = async (
        degreeTypes?: number[] | [],
        auspice?: number[] | [],
    ): Promise<Blob | undefined> => {
        const result = await httpDownloadFile(
            APIUriAms,
            `/report/export-degrees-by-auspice?withConcentrations=${
                degreeTypes && degreeTypes?.length > 0 ? '&degreeTypes=' + degreeTypes.join('&degreeTypes=') : ''
            }${auspice && auspice?.length > 0 ? '&auspice=' + auspice.join('&auspice=') : ''}`,
            undefined,
        );
        if (result.ok) {
            try {
                return result.parsedBody;
            } catch (ex) {
                throw ex;
            }
        } else {
            throw Error(result.statusText);
        }
    };
    public getInactiveStatusReport = async (): Promise<InactiveStatusReportDto> => {
        const result = await http<InactiveStatusReportDto>(
            APIUriAms,
            `/report/inactive-status`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public exportInactiveStatusReport = async (): Promise<Blob | undefined> => {
        const result = await httpDownloadFile(APIUriAms, `/report/export-inactive-status`, undefined);
        if (result.ok) {
            try {
                return result.parsedBody;
            } catch (ex) {
                throw ex;
            }
        } else {
            throw Error(result.statusText);
        }
    };

    public getProbationStatusReport = async (): Promise<ProbationStatusReportDto> => {
        const result = await http<ProbationStatusReportDto>(
            APIUriAms,
            `/report/probation-status`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public exportProbationStatusReport = async (): Promise<Blob | undefined> => {
        const result = await httpDownloadFile(APIUriAms, `/report/export-probation-status`, undefined);
        if (result.ok) {
            try {
                return result.parsedBody;
            } catch (ex) {
                throw ex;
            }
        } else {
            throw Error(result.statusText);
        }
    };
    public getProfessionSearchCountReport = async (): Promise<ProfessionSearchCountReportDto> => {
        const result = await http<ProfessionSearchCountReportDto>(
            APIUriAms,
            `/report/profession-search-count`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public exportProfessionSearchCountReport = async (): Promise<Blob | undefined> => {
        const result = await httpDownloadFile(APIUriAms, `/report/export-profession-search-count`, undefined);
        if (result.ok) {
            try {
                return result.parsedBody;
            } catch (ex) {
                throw ex;
            }
        } else {
            throw Error(result.statusText);
        }
    };
    public getNumberOfActiveProgramsReport = async (
        cutOffDate: string | Date,
        withConcentrations: boolean | undefined,
    ): Promise<NumberOfActiveProgramsReportDto> => {
        const result = await http<NumberOfActiveProgramsReportDto>(
            APIUriAms,
            `/report/active-program-count?cutOffDate=${cutOffDate}&withConcentrations=${withConcentrations}`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public exportNumberOfActiveProgramsReport = async (
        cutOffDate: string | Date,
        withConcentrations: boolean | undefined,
    ): Promise<Blob | undefined> => {
        const result = await httpDownloadFile(
            APIUriAms,
            `/report/export-active-program-count?cutOffDate=${cutOffDate}&withConcentrations=${withConcentrations}`,
            undefined,
        );
        if (result.ok) {
            try {
                return result.parsedBody;
            } catch (ex) {
                throw ex;
            }
        } else {
            throw Error(result.statusText);
        }
    };

    public getProfitVsPublicReport = async (
        paging: PagingDto,
        institutionTypeIds?: number[] | [],
        auspiceIds?: number[] | [],
    ) => {
        const result = await http<ProfitVsPublicDto[]>(
            APIUriAms,
            `/report/profit-vs-public?${
                institutionTypeIds && institutionTypeIds?.length > 0
                    ? '&institutionTypeIds=' + institutionTypeIds.join('&institutionTypeIds=')
                    : ''
            }${
                auspiceIds && auspiceIds?.length > 0 ? '&auspiceIds=' + auspiceIds.join('&auspiceIds=') : ''
            }&pageNumber=${paging.pageNumber}&pageSize=${paging.pageSize}`,
            undefined,
            undefined,
            null,
        );
        const pagingHeader = result.headers.get('X-Pagination');
        const total = pagingHeader ? JSON.parse(pagingHeader).TotalCount : 0;
        if (result.parsedBody) {
            return {
                records: result.parsedBody,
                total: total,
            };
        } else {
            throw new Error(result.statusText);
        }
    };

    public exportProfitVsPublicReport = async (
        institutionTypeIds?: number[] | [],
        auspiceIds?: number[] | [],
    ): Promise<Blob | undefined> => {
        const result = await httpDownloadFile(
            APIUriAms,
            `/report/export-profit-vs-public?institutionTypeIds=${
                institutionTypeIds && institutionTypeIds?.length > 0
                    ? institutionTypeIds.join('&institutionTypeIds=')
                    : ''
            }${auspiceIds && auspiceIds?.length > 0 ? '&auspiceIds=' + auspiceIds.join('&auspiceIds=') : ''}`,
            undefined,
        );
        if (result.ok) {
            try {
                return result.parsedBody;
            } catch (ex) {
                throw ex;
            }
        } else {
            throw Error(result.statusText);
        }
    };
    public getWithdrawStatusReport = async (
        startDate: string | Date,
        endDate: string | Date,
    ): Promise<WithdrawStatusReportDto> => {
        const result = await http<WithdrawStatusReportDto>(
            APIUriAms,
            `/report/withdraw-status?startDate=${startDate}&endDate=${endDate}`,
            undefined,
            undefined,
            null,
        );
        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public exportWithdrawStatusReport = async (
        startDate: string | Date,
        endDate: string | Date,
    ): Promise<Blob | undefined> => {
        const result = await httpDownloadFile(
            APIUriAms,
            `/report/export-withdraw-status?startDate=${startDate}&endDate=${endDate}`,
            undefined,
        );
        if (result.ok) {
            try {
                return result.parsedBody;
            } catch (ex) {
                throw ex;
            }
        } else {
            throw Error(result.statusText);
        }
    };
}

export const reportService = new ReportService();
