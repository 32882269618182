import { APIUriAms, http, httpDownloadFile, httpFile } from './HttpHelper';
import {
    AccreditationActionsIndexReport,
    CitationDto,
    Institution,
    InstitutionSearchResult,
    LetterTemplateDto,
    Program,
    RecommendationCreate,
    RecommendationDto,
    RecommendationListItem,
    RecommendationProgramSearchResult,
    RecommendationReportProfessions,
    RecommendationSatellite,
    RelatedDocumentDto,
    StandardReport,
} from '../common/Types';

class RecommendationService {
    public search = async (
        meetingDateId?: number,
        professionId?: number,
        newOnly?: boolean,
        concentrationId?: number,
        levelId?: number,
    ): Promise<RecommendationListItem[]> => {
        const result = await http<RecommendationListItem[]>(
            APIUriAms,
            `/recommendation/search?meetingDateId=${meetingDateId || ''}&professionId=${professionId || ''}&newOnly=${
                newOnly === true ? true : newOnly === false ? false : ''
            }&concentrationId=${concentrationId || ''}&levelId=${levelId || ''}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody.sort((a, b) => a.institutionTitle.localeCompare(b.institutionTitle));
        } else {
            throw new Error(result.statusText);
        }
    };

    public institutionSearch = async (
        echo: number,
        city?: string,
        state?: string,
        institutionTitle?: string,
        professionId?: number,
        concentrationId?: number,
        levelId?: number,
        addOnTrackId?: number,
    ): Promise<InstitutionSearchResult> => {
        const result = await http<InstitutionSearchResult>(
            APIUriAms,
            `/recommendation/institution/search/${echo}?city=${city || ''}&state=${
                state || ''
            }&institutionTitle=${encodeURI(institutionTitle || '')}&professionId=${
                professionId || ''
            }&concentrationId=${concentrationId || ''}&levelId=${levelId || ''}&addOnTrackId=${addOnTrackId || ''}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public programSearch = async (
        institutionId: number,
        professionId: number,
        concentrationId?: number,
        levelId?: number,
        addOnTrackId?: number,
        award?: string,
    ): Promise<RecommendationProgramSearchResult> => {
        const result = await http<RecommendationProgramSearchResult>(
            APIUriAms,
            `/recommendation/program/search/${institutionId}/${professionId}?concentrationId=${
                concentrationId || ''
            }&levelId=${levelId || ''}&addOnTrackId=${addOnTrackId || ''}&award=${award}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getById = async (recommendationId: number): Promise<RecommendationDto> => {
        const result = await http<RecommendationDto>(
            APIUriAms,
            `/recommendation/${recommendationId}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getCitationsByRecommendationId = async (recommendationId: number): Promise<CitationDto[]> => {
        const result = await http<CitationDto[]>(
            APIUriAms,
            `/recommendation/${recommendationId}/citations`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public createRecommendation = async (recommendation: RecommendationCreate) => {
        const result = await http<RecommendationDto>(APIUriAms, `/recommendation`, undefined, 'POST', recommendation);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public updateRecommendation = async (recommendation: RecommendationDto) => {
        const result = await http<RecommendationDto>(APIUriAms, `/recommendation`, undefined, 'PATCH', recommendation);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public updateInstitution = async (recommendationId: number, institutionId?: number) => {
        const result = await http<RecommendationDto>(
            APIUriAms,
            `/recommendation/change-institution`,
            undefined,
            'PATCH',
            {
                recommendationId,
                institutionId,
            },
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public mergeRecommendation = async (recommendation: RecommendationDto) => {
        const result = await http<RecommendationDto>(
            APIUriAms,
            `/recommendation/merge`,
            undefined,
            'PATCH',
            recommendation,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveCitationAndDetail = async (citation: CitationDto) => {
        const result = await http<CitationDto>(APIUriAms, `/recommendation/citation`, undefined, 'PUT', citation);

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public removeCitation = async (citationId: number) => {
        const result = await http<CitationDto>(
            APIUriAms,
            `/recommendation/citation/${citationId}`,
            undefined,
            'DELETE',
            undefined,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public removeRecommendation = async (recommendationId: number) => {
        const result = await http<CitationDto>(
            APIUriAms,
            `/recommendation/${recommendationId}`,
            undefined,
            'DELETE',
            undefined,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public getSatellitesByRecommendationId = async (recommendationId: number): Promise<RecommendationSatellite[]> => {
        const result = await http<RecommendationSatellite[]>(
            APIUriAms,
            `/recommendation/${recommendationId}/satellites`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public saveSatellite = async (satellite: RecommendationSatellite) => {
        const result = await http<RecommendationSatellite>(
            APIUriAms,
            `/recommendation/satellite`,
            undefined,
            'PUT',
            satellite,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public generateCertificates = async (recommendationIds: number[]): Promise<Blob | undefined> => {
        const result = await httpDownloadFile(
            APIUriAms,
            `/recommendation/certificates?recommendationIds=${recommendationIds.join('&recommendationIds=')}`,
            undefined,
        );

        if (result.ok) {
            try {
                return result.parsedBody;
            } catch (ex) {
                throw ex;
            }
        } else {
            throw Error(result.statusText);
        }
    };

    public generateCitationLetter = async (recommendationId: number): Promise<Blob | undefined> => {
        const result = await httpDownloadFile(
            APIUriAms,
            `/recommendation/${recommendationId}/citation-letter`,
            undefined,
        );

        if (result.ok) {
            try {
                return result.parsedBody;
            } catch (ex) {
                throw ex;
            }
        } else {
            throw Error(result.statusText);
        }
    };

    public generateMeetingSummeryReport = async (meetingDateId: number): Promise<Blob | undefined> => {
        const result = await httpDownloadFile(
            APIUriAms,
            `/report/recommendation/summary-report?meetingDateId=${meetingDateId}`,
            undefined,
        );

        if (result.ok) {
            try {
                return result.parsedBody;
            } catch (ex) {
                throw ex;
            }
        } else {
            throw Error(result.statusText);
        }
    };

    public getAwardLetterTemplate = async (recommendationId: number): Promise<LetterTemplateDto | undefined> => {
        const result = await http<LetterTemplateDto>(
            APIUriAms,
            `/recommendation/${recommendationId}/award-letter`,
            undefined,
            undefined,
            null,
        );

        return result.parsedBody;
    };

    public generateAccreditationActionIndexReport = async (
        meetingDateId?: number,
        professionId?: number,
        newOnly?: boolean,
    ): Promise<AccreditationActionsIndexReport> => {
        const result = await http<AccreditationActionsIndexReport>(
            APIUriAms,
            `/report/recommendation/accreditation-action-index?meetingDateId=${meetingDateId || ''}&professionId=${
                professionId || ''
            }&newOnly=${newOnly || ''}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public exportAccreditationActionIndexReport = async (
        meetingDateId?: number,
        professionId?: number,
        newOnly?: boolean,
        excelDoc?: boolean,
    ): Promise<Blob | undefined> => {
        const result = await httpDownloadFile(
            APIUriAms,
            `/report/recommendation/export-accreditation-action-index?meetingDateId=${
                meetingDateId || ''
            }&professionId=${professionId || ''}&newOnly=${newOnly || ''}&excelDoc=${excelDoc || ''}`,
            undefined,
        );

        if (result.ok) {
            try {
                return result.parsedBody;
            } catch (ex) {
                throw ex;
            }
        } else {
            throw Error(result.statusText);
        }
    };

    public downloadReport = async (
        reportType?: string,
        meetingDateId?: number,
        professionId?: number,
        newOnly?: boolean,
    ): Promise<Blob | undefined> => {
        const result = await httpDownloadFile(
            APIUriAms,
            `/report/recommendation/${reportType}?meetingDateId=${meetingDateId || ''}&professionId=${
                professionId || ''
            }&newOnly=${newOnly || ''}`,
            undefined,
        );

        if (result.ok) {
            try {
                return result.parsedBody;
            } catch (ex) {
                throw ex;
            }
        } else {
            throw Error(result.statusText);
        }
    };

    public meetingYearsByProfessionId = async (professionId: number): Promise<number[]> => {
        const result = await http<number[]>(
            APIUriAms,
            `/recommendation/meeting-years/${professionId}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };

    public standardsReportByProfessionIdYear = async (professionId: number, year: number): Promise<StandardReport> => {
        const result = await http<StandardReport>(
            APIUriAms,
            `/recommendation/standard-report/${professionId}/${year}`,
            undefined,
            undefined,
            null,
        );

        if (result.parsedBody) {
            return result.parsedBody;
        } else {
            throw new Error(result.statusText);
        }
    };
}

export const recommendationService = new RecommendationService();
